
export const ContactUsFormData = {
  "cU_id": 0,
  "phoneNo": "",
  "emailId": "",
  "senderName": "",
  "message": "",
  "createdDate": new Date(),
  "replyDate":new Date(),
  "replyMessage": "",
  "replyBy": 0
}



export const ApiHeaders = {
  "UserType": process.env.REACT_APP_USER_TYPE,
  "Content-Type": "application/json"
}


export const ApiHeader = {
 "Content-Type": "application/json"
}

export const DefaultDate= "1900-01-01T00:00:00";

export const CurrentDateWithoutFormat = new Date()

export const CurrentDate = new Date().toISOString().split('T')[0];
export const CurrentDateTime = new Date().toISOString().slice(0, 16)


export const ApiErrorMessage = "Error while processing request"

