import React from "react";
import { Link } from "react-router-dom";
import mainLogo from "../images/logo.webp";
const LogoContent = () => {
  return (
    <Link to="/" className="navbar-brand">
      <img
        id="cloneLogo"
        className="light-mode-item navbar-brand-item"
        src={mainLogo}
        alt="logo"
      />
      <img
        className="dark-mode-item navbar-brand-item"
        src={mainLogo}
        alt="logo"
      />
    </Link>
  );
};

export default LogoContent;
