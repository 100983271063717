import React from "react";
import { Link } from "react-router-dom";

const MenuContent = ({setPage}) => {

  const handlePage=(value)=>{
    setPage(value)
  }
  return (
    <ul className="navbar-nav navbar-nav-scroll ms-auto" id="cloneMenu">
      <li className="nav-item">
        <a className="nav-link" style={{cursor:"pointer"}} onClick={()=>handlePage("Home")}>Home</a>
        {/* <Link className="nav-link" to="/">
          Home
        </Link> */}
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{cursor:"pointer"}} onClick={()=>handlePage("About Us")}> About Us</a>
        {/* <Link className="nav-link" to="/about">
          About Us
        </Link> */}
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{cursor:"pointer"}} onClick={()=>handlePage("Contact Us")}>  Contact Us</a>
       {/* <Link className="nav-link" to="/contact-us">
          Contact Us
          </Link> */}
      </li>
      
    </ul>
  );
};

export default MenuContent;
