

import { createContext, useContext, useState } from "react"

const ManageBookingContext = createContext();
export const ManageBookingProvider = ({ children })=>{
  const [eachbookingData, setEachBookingData] = useState({});

  
  return(
    <ManageBookingContext.Provider value={{eachbookingData, setEachBookingData}}>
      {children}
    </ManageBookingContext.Provider>
  )
}

export const useBookingManageData = () =>{
  return useContext(ManageBookingContext)
}
