import { useEffect } from "react";
import { useLoading } from "../hooks/useLoading";

const Loader = () =>{
  const {isLoading} = useLoading();
  useEffect(() => {
        // console.log(isLoading)
        if(isLoading){
        
        // Disable scrolling while the loader is active
       
        }
        else{
          
        }
       

        // Simulate loading and hide loader after 3 seconds
        
    }, [isLoading]);
    if (isLoading === false) return null;
  return(
    <div className="loader">
	<div className="loader_icon"></div>
</div>
  )
}

export default Loader;