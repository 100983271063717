import React from "react";
import { Modal, Button } from "react-bootstrap";

const OtherTermsModal = ({ show, handleClose }) => {
  return (
    <Modal
      
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="modal-content rounded-3">
        <Button
          type="button"
          className="btn-close closeModal"
          onClick={handleClose}
          aria-label="Close"
          
        ></Button>
        <div className="otherTerms modal-body p-0 overflow-hidden">
          <div className="instructions">
            <h6 className="text-center">Other Charges and Taxes</h6>
            <div className="row">
              <div className="col-md-12">
                <p>
                  <strong>- </strong> Vehicle and fuel charges included
                </p>
                <p>
                  <strong>- </strong> Driver Night Charges included
                </p>
                <p>
                  <strong>- </strong> For Round trip bookings, all the local
                  sightseeing in the destination cities is included except for
                  Sikkim and Darjeeling. For local sightseeing in Pickup city
                  (if required), please add all the Pickup city stops in
                  itinerary.
                </p>
                <p>
                  <strong>- </strong> 5% GST Extra
                </p>
                <p>
                  <strong>- </strong> Included Kilometers will start from pickup
                  location
                </p>
                <p>
                  <strong>- </strong> Driver allowance includes driver's stay,
                  food, and night charges
                </p>
                <p>
                  <strong>- </strong> Toll and state tax included
                </p>
                <p>
                  <strong>- </strong> Parking charges extra if applicable
                </p>
                <p>
                  <strong>- </strong> AC will remain switched off in hill areas
                </p>
                <p>
                  <strong>- </strong> For round trip booking, Kilometers will
                  count from pickup location to pickup location
                </p>
                <p>
                  <strong>- </strong> One way trip includes only one pickup and
                  one drop. Additional pickup or drop on the way will incur
                  additional charges of Rs. 250 per pickup/drop.
                </p>
                <p>
                  <strong>- </strong> Rohtang Pass needs a special permit and is
                  not included in any trip
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center p-2">
          <Button type="button" className="btn btn-dark mb-0" onClick={handleClose}>
            Agree
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OtherTermsModal;
