import React, { useEffect, useState } from "react";
import Header from "../common/Header";

import PopularDestinations from "./PopularDestinations";
import PartnerWithUs from "./PartnerWithUs";
import AppDownloadSection from "./AppDownloadSection";
import FAQAccordion from "./FAQAccordion";
import Footer from "../common/Footer";
import BackToTop from "../common/BackToTop";
import MainBanner from "./MainBanner";
import WhyChooseUs from "./WhyChooseUs";
import Aboutus from "../aboutus/Aboutus";
import Contact from "../ContactUs/Contact";
import UserProfile from "../Profile/UserProfile";
import { GetHomePageData, scrollToTop } from "../common/GeneralMethod";
import { useLoading } from "../hooks/useLoading";
import { NotificationManager } from "react-notifications";
import popDestination from '../images/sliderImg.webp'
import { usePage } from "../hooks/usePage";
import Policy from "../Components/Policy";
import { ApiErrorMessage } from "../common/ConstStates";
import TravelInfo from "./TravelInfo";
function Home() {
  const {startLoading, stopLoading} = useLoading();
  const {page, setPage} = usePage()
  const [popularDestinations, setPopularDestinations] = useState([])
  const [faqs, setFaqs] = useState([])
  const [popularCities, setPopularCities] = useState([])
   const [popularCitiesNames, setPopularCitiesNames] = useState([])
  useEffect(()=>{
    if(page==="Home"){
      getHomePageData();
    }
    scrollToTop()
  },[page])

  const getHomePageData = async()=>{
    startLoading();
    const response = await GetHomePageData()
    if(response!==null ){
      // Popular destinations
      const popularDes = response.responsePopularDestinations
       if(popularDes!==null){
      if(popularDes.data.status==="200"){
      const arr = [];
      popularDes.data.data.map((data,index)=>{
        arr.push({...data, imgSrc:popDestination})
      })
      setPopularDestinations([...arr])
      
    }
    else if(popularDes.data.status==="401"){
      NotificationManager.error(ApiErrorMessage)
      setPopularDestinations([])
      stopLoading()
      return
    }
    else{
      setPopularDestinations([])
      
    }

    // FAQ
 const FAQ = response.responseFQA
  
      if(FAQ!==null){
        if(FAQ.data.status === "401"){
          NotificationManager.error(ApiErrorMessage)
          setPopularDestinations([])
          stopLoading()
          return
        }
      setFaqs([...FAQ.data.data])
      stopLoading();
    }
    else{
      NotificationManager.error("Error while fetching data")
      stopLoading()
    }

    // Popular cities
     const popCities = response.responsePopularCities
     if(popCities!==null || popCities!==undefined){
      if(popCities.data.status==="200"){
      // console.log(response)
      const result = popCities.data.data
      const uniqueNames = [...new Set(result.map(item => item.pickupCity))];
      setPopularCities([...result])
      setPopularCitiesNames([...uniqueNames])
      stopLoading()
    }
    else{
      setPopularCities([])
      setPopularCitiesNames([])
      stopLoading()
    }
    }
    else{
      NotificationManager.error(ApiErrorMessage)
    }
  } 
    else{

    }
  }
}
  return (
    <div>
      <Header ></Header>
      {/* When Home Clicked */}
      {page==="Home" &&  
      <div>
      <MainBanner popularCities={popularCities} popularCitiesNames={popularCitiesNames}></MainBanner>

      <WhyChooseUs></WhyChooseUs>

      <PopularDestinations sliderItems={popularDestinations}></PopularDestinations>

      <PartnerWithUs></PartnerWithUs>

      <AppDownloadSection></AppDownloadSection>

      <FAQAccordion faqs ={faqs}></FAQAccordion>
      </div>
      }

      {/* About Us Click */}
      {page==="About Us" && <Aboutus></Aboutus>}
     
      {/* Contact Us Click*/}
      {page==="Contact Us" && <Contact></Contact>}
      {page === "Profile" && <UserProfile></UserProfile>}
      {page==="PrivacyPolicy" && <Policy apiName="TermsAndPolicies/PrivacyPolicy" heading="Privacy Policy"></Policy>}

       {page==="TermsAndPolicies" && <Policy apiName="TermsAndPolicies/GetTermsAndUses" heading="Terms & Conditions"></Policy>}

         {page==="CancellationPolicy" && <Policy apiName="TermsAndPolicies/CancellationPolicy" heading="Refund Policy"></Policy>}

         {page==="travelinfo" &&<div><MainBanner popularCities={popularCities} popularCitiesNames={popularCitiesNames}></MainBanner><TravelInfo></TravelInfo>
</div> }

      <Footer popularCities={popularCities} popularCitiesNames={popularCitiesNames}></Footer>

      <BackToTop></BackToTop>
    </div>
  );
}

export default Home;
