import React, {useEffect, useState} from "react";
import BackToTop from "../common/BackToTop";
import Footer from "../common/Footer";
import Header from "../common/Header";
import carImage from "../images/creta.jpg"
import OtherTermsModal from "./OtherTermsModal";
import { useLocation } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import DatePickerModal from "./DatePickerModal";
import { useAuth } from "../hooks/useAuth";
import { useLoginStep } from "../hooks/useLoginStep";
import { useLoginModal } from "../hooks/useLoginModal";
import LoginModal from "../common/LoginModal";
import { useBookNowModal } from "../hooks/useBookNowModal";
import BookNow from "./BookNow";
import {convertDatetoReadFormat, convertTimestamp} from '../common/GeneralMethod'
import { useBookingData } from "../hooks/useBookingData";

const BookingNew = () =>{
  const {user} = useAuth()
  const {step, setStep } = useLoginStep(); 
  const { showBookNowModal, setBookNowModalState} = useBookNowModal();
  const {bookData, setBookData} = useBookingData()
  const {state} = useLocation();
  const [bookingData, setBookingData] = useState({})
  const [bookingResults, setBookingResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAccessible, setIsAccessible] = useState(true);
 
  
   const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(()=>{
    
    if(state === null){
      setIsAccessible(false)
      return
    }
    else{   
     
      setBookingData({...state.data})
      setBookingResults([...state.result])
    }
    
  },[state])

  useEffect(()=>{
    //console.log('setting booking data')
    if(JSON.stringify(bookingData)!=="{}"){
      setIsLoading(false)
    }
  },[bookingData])

  

  const computeTotalCharges= (totalFare, toll, driverCharges, nightCharges)=>{
    return (parseFloat(totalFare) + parseFloat(toll) + parseFloat(driverCharges) + parseFloat(nightCharges))
  }

  const {showLoginModal, setShowLoginModal} = useLoginModal();

  const closeSignUpModal = () => {
    setShowLoginModal(false);
  };

  const handleBookNowClick = (data) =>{
   
    setBookData({...data})
    // console.log(user!==null, window.localStorage.getItem("user") !==null)
    if(user===null || window.localStorage.getItem("user") ===null){
      // console.log('isnide this')
      setStep(2)
     // setBookingData({...bookingData})
      setShowLoginModal(true)
    }
    else{
      
     
      setBookNowModalState(true)
    }
    
  }
  return(
    <div>
      <Header></Header>
      {
        isAccessible ? 
        (
          JSON.stringify(bookingData)!=="{}" ? 
          (bookingResults.length > 0 ? 
          (
            <main>
              <div className="main-content my-5 py-3">
                <div className="container">
                  <div className="row gap-0">
                    <div className="col-lg-12">
                      <div className="card whitewrap shadow rounded">
                        <div className="card-body p-0">
                          <div className="headings border-bottom p-3">
                            <h4 className="mb-0">Booking Details</h4>
                            <p className="mb-0">{bookingData.bookingType ==="oneway" ? "One-Way": "Round Trip"}</p>
                          </div>

                          {/* Booking Details */}
                          <div className="bookingDetails">
                            <div className="roundTrip">
                              <ul>
                                <li>
                                  <p className="mb-0">{bookingData.pickupLocation}</p>
                                </li>
                                {
                                  bookingData.intermediates.length > 0 && bookingData.intermediates.map((data,index)=>{
                                    return(
                                       <li key={index}>
                                      <p className="mb-0">{data.interLocation}</p>
                                    </li>
                                    )
                                  })                            
                                }
                               
                                <li>
                                  <p className="mb-0">{bookingData.dropOffLocation}</p>
                                </li>
                              </ul>
                            </div>

                            {/* Customer Details */}
                            <div className="custDetails p-3">
                              <div className="row">
                                <div className="col-lg-2">
                                  <div className="fw-bold">Phone Number</div>
                                  <div><small>{bookingData.userPhone}</small></div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="fw-bold">Estimate Time</div>
                                  <div><small>{bookingResults.length > 0 ? JSON.stringify(bookingResults[0].timeDurationInHour) + ' Hr': 0}</small></div>
                                </div>
                                
                                <div className="col-lg-2">
                                  <div className="fw-bold">Goods/Luggage</div>
                                  <div><small>{bookingResults.length > 0 ? bookingResults[0].luggageAllowed : "0 bags Allowed"}</small></div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="fw-bold">Total Distance</div>
                                  <div><small>{bookingResults.length > 0 ? JSON.stringify(bookingResults[0].tripDistance) + ' KM': 0}</small></div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="fw-bold">{'GST Included' + (bookingResults.length > 0 ? "("+bookingResults[0].gstRate + "%)" : '(0%)')}</div>
                                  <div><small>{bookingResults.length > 0 ? 'Rs. ' + bookingResults[0].gstAmount : 'Rs. 0' }</small></div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="fw-bold">Pickup Date</div>
                                  <div><small>{convertDatetoReadFormat(bookingData.pickupTime)}</small></div>
                                </div>
                              </div>
                            </div>

                            {/* Cars Details */}
                            <div className="selectCar p-3 border-top">
                              <h5 className="mb-1">Select Cars</h5>
                              <div className="car-container">
                                <div className="row g-2">
                                  {
                                    bookingResults.length > 0 && bookingResults.map((data,index)=>(

                                     
                                      <div className="col-md-3 col mb-0" key={index}>
                                      <div className="car-select card card-body shadow p-0 position-relative">
                                        <div className="p-3">
                                          <div>
                                            <img src={carImage} alt={data.vehicleType} />
                                          </div>
                                        </div>
                                        {/* <div className="price_block">
                                          <div className="default">
                                            <del>
                                              <i className="fa-solid fa-indian-rupee-sign"></i>
                                              <span>{data.offerPrice}</span>
                                            </del>
                                          </div>
                                          <div className="actual">
                                            <i className="fa-solid fa-indian-rupee-sign"></i>
                                            <span>{data.totalFare}</span>
                                          </div>
                                        </div> */}
                                        <h6 className="text-center">{data.vehicleType}</h6>
                                        <p className="mb-0 type_list">{data.vehicleName}</p>
                                        <div className="price_details">
                                          <ul className="price_break">
                                            <li>
                                              <div>Capacity</div>
                                              <div>{data.vehicleSeaterCount}</div>
                                            </li>
                                            <li>
                                              <div>Km Charges(Rs.)</div>
                                              <div>{data.totalFare}</div>
                                            </li>
                                            <li>
                                              <div>Toll, State Tax</div>
                                              <div>
                                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                                <span>{data.tollTaxAmount === 0 ? "Included" : data.tollTaxAmount}</span>
                                              </div>
                                            </li>
                                            <li>
                                              <div>Driver Charges</div>
                                              <div>{data.driverChargesAmount === 0 ? "Included"  : data.driverChargesAmount}</div>
                                            </li>
                                            <li>
                                              <div>Night Charges</div>
                                              <div>{data.nightChargesAmount === 0 ? "Included" : data.nightChargesAmount}</div>
                                            </li>
                                            <li>
                                              <div>Total Charges(Rs.)</div>
                                              <div>{data.totalFare}</div>
                                            </li>
                                            <li>
                                              <div>{'Discount('+data.discountPercent +'%)'}</div>
                                              <div>{data.discountAmount}</div>
                                            </li>
                                          </ul>
                                           <a className="otherTerms" onClick={handleShow}>
                                            Other Terms
                                          </a>
                                         
                                        </div>
                                        <div className="book_now">
                                          <a className="btn btn-dark mb-0" onClick={()=>handleBookNowClick(data)}>Book Now</a>
                                        </div>
                                      </div>
                                    </div>
                                    
                                 
                                    ))
                                  }
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               <LoginModal
        show={showLoginModal}
        handleClose={closeSignUpModal}
        phoneNo={bookingData.userPhone}
      ></LoginModal>
      
            </main>
          ) : <h5>No Cars Available</h5> ): <h5>Oops! Something Went Wrong</h5>
        )
        : <h5>Oops! Something Went Wrong</h5>
        
      }
      <BookNow bookingData={bookingData}></BookNow>

      <Footer popularCities={state.popularCities} popularCitiesNames={state.popularCitiesNames}></Footer>
      <BackToTop></BackToTop>

      <OtherTermsModal show={showModal} handleClose={handleClose} />
     

    </div>
  )
}

export default BookingNew;