import { useEffect, useState } from "react";
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import Swiper from 'swiper/bundle';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import 'bootstrap/dist/js/bootstrap.bundle.min';

import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import DatePickerModal from "../BookingPage/DatePickerModal";
import DropDownInputBox from "../Components/DropDownInputBox";
import Loader from "../Components/Loader";
import PhoneInputCom from "../Components/PhoneInput";
import { ApiErrorMessage, CurrentDate, DefaultDate } from "../common/ConstStates";
import { callApi, getTomorrowAtSixAM } from "../common/GeneralMethod";
import { useAuth } from "../hooks/useAuth";
import { useLoading } from "../hooks/useLoading";
import sliderImg from '../images/slider/slider-1.webp';
SwiperCore.use([Pagination, Navigation]);

const MainBanner = ({popularCities, popularCitiesNames}) =>{
  const {user} = useAuth()
  const navigate = useNavigate();
  const {startLoading, stopLoading} = useLoading();
  // SELECTED VALUES
  const MainBannerFormData = {
  "pickupTime": getTomorrowAtSixAM(),
  "dropOffTime": DefaultDate,
  "bookingType": process.env.REACT_APP_BOOKING_TYPE_ONE_WAY,
  "userPhone": user!==null ? user.phoneNo : "",
  "pickupLocation": "",
  "pickupLocationId": "",
  "dropOffLocation": "",
  "dropOffLocationId": "",
  "intermediates": []
  }

 
  useEffect(()=>{
    setFormData({...formData, userPhone: user!==null ? user.phoneNo : ""})
  },[user])
  const [formData, setFormData] = useState({...MainBannerFormData})

  const [isFormDataReset, setIsFormDataReset] = useState(false)
//  const [selectedOption, setSelectedOption] = useState("oneway");
 // const [selectedPickupLocation, setSelectedPickupLocation] = useState({})
  //const [selectedDropLocation, setSelectedDropLocation] = useState([])
 // const [phoneNumber, setPhoneNumber] = useState('')
  const [totalDropLocation, setTotalDropLocation] = useState(1)

  //MODAL
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
  //  const {showLoginModal, setShowLoginModal} = useLoginModal();

  //  const closeSignUpModal = () => {
  //   setShowLoginModal(false);
  // };

  const closeDatePickerModal = ()=>{
    setIsDatePickerModalOpen(false)
  }


	useEffect(()=>{
    // console.log('inside use effect')
    setFormData({...MainBannerFormData})
	 const swiper = new Swiper('.mySwiper', {
      direction: 'horizontal',
      loop: true,
      loopedSlides: 50,
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      // autoplay: {
      //  delay: 5000,
      // },
    });
  },[])

  // Handle booking type change
  const handleOptionChange = (event) => {
    // console.log(MainBannerFormData, event.target.value)
    setFormData({...MainBannerFormData, "bookingType": event.target.value})
  setTotalDropLocation(1)
   setIsFormDataReset(true)
    //setFormData({...formData, "bookingType": event.target.value})
  
  };

  //handle pickup location change
  const handleOnSelectPickup = (value,key) =>{
     setIsFormDataReset(false)
    setFormData({...formData, "pickupLocation": value.text, "pickupLocationId": value.placeId})
    // console.log(formData)
    //setSelectedPickupLocation({...value})
  }

  const handleOnSelectDrop = (value,key) =>{
    setIsFormDataReset(false)
    // console.log(value, key)
    if(key===0){
      setFormData({...formData, "dropOffLocation": value.text, "dropOffLocationId": value.placeId})
    }
    else{
      let arr = formData.intermediates
      if(arr.length ===0){
        arr.push({"interLocation": value.text, "interLocationId":value.placeId})
        setFormData({...formData, "intermediates":[...arr]})
      }
      else if((key) > arr.length){
        arr.push({"interLocation": value.text, "interLocationId":value.placeId})
        setFormData({...formData, "intermediates":[...arr]})
      }
      else if((key-1) <= arr.length){
        const newArr = arr.map((data,index)=>{
          if(index===key-1){
            return({"interLocation": value.text, "interLocationId":value.placeId})
          }
          else{
            return {...data}
          }
        })
        setFormData({...formData, "intermediates":[...newArr]})
      }
    }
    //setSelectedDropLocation([...selectedDropLocation, {...value}])
  }

  const onChangePhoneInput = (value)=>{
    setIsFormDataReset(false)
    setFormData({...formData, "userPhone": value.replace("+91","")})
    //setPhoneNumber(value)
  }

  const handleMoreDestinationClick = () =>{
    setIsFormDataReset(false)
    // console.log(totalDropLocation, process.env.REACT_APP_TOTAL_DESTINATION)
    if(totalDropLocation == process.env.REACT_APP_TOTAL_DESTINATION){
      NotificationManager.warning('You can add up to 9 destinations')
      return
    }
    setTotalDropLocation(totalDropLocation+1)
  }

  const handleSearch = () =>{
    if(formData.pickupLocationId === ""){
      NotificationManager.error("Pickup location is required.")
      return
    }
    if(formData.dropOffLocationId === ""){
      NotificationManager.error("Drop location is required.")
      return
    }
    if (formData.userPhone === "+91") {
      NotificationManager.error("Phone No is required.");
      return;
    }
    if (formData.userPhone.length < 10) {
      NotificationManager.error("Invalid Phone No");
      return;
    }
    
    setIsDatePickerModalOpen(true)
    // console.log(selectedPickupLocation)
    //   console.log(selectedDropLocation)
    //   console.log(phoneNumber)
    // navigate('/booking',{state:{pickUp: {...selectedPickupLocation}, drop: [...selectedDropLocation], type:selectedOption, phoneNumber:phoneNumber}})
    //   navigate('/booking')
    //  if(window.localStorage.getItem("user") !==null && user!==null)
    //  {  
    //  }
    //  else{
    //   setShowLoginModal(true);
    //  }
  }

  const handlePickUpDateChange = (value) =>{
    // console.log("inside picup date", value)
    setFormData({...formData, "pickupTime":value})
  }
  const handleDropDateChange = (value) =>{
    setFormData({...formData, "dropOffTime":value})
  }

  const handleGetPRiceBtnClick = async() =>{
    if(formData.bookingType === process.env.REACT_APP_BOOKING_TYPE_ONE_WAY){
      if(formData.pickupTime === ""){
        NotificationManager.error("Pick Up Date is required")
        return
      }
      
    }
    else{
      // console.log(formData)
      if(formData.pickupTime === ""){
        NotificationManager.error("Pick Up Date is required")
        return
      }
      if(formData.dropOffTime === ""){
        NotificationManager.error("Drop Date is required")
        return
      }
       const pickUpDate = new Date(formData.pickupTime);
    const dropDate = new Date(formData.dropOffTime);
    if(pickUpDate > dropDate){
      NotificationManager.error('Pickup date must be before drop-off date')
      return
    }
    }
   
    startLoading()
    const response = await callApi('post', 'Booking/computeRoutes', formData, {
        "UserType": "0",
        "Content-Type": "application/json",
      })
stopLoading();
// console.log('response',response)
    if(response!==null){
       if(response.data.status === "200"){
        
         navigate('/booking',{state:{data:{...formData}, result: [...response.data.data], popularCities:[...popularCities], popularCitiesNames:[...popularCitiesNames]}})
      }
      else if(response.data.status === "400"){
 NotificationManager.warning(response.data.message)
      }
      else{
        NotificationManager.error(ApiErrorMessage)
      }
      // console.log(response)
    }
    else{
NotificationManager.error(ApiErrorMessage)
    }
    // await axios({
    //    method: "post",
    //   url:process.env.REACT_APP_API_URL + 'Booking/computeRoutes',
      
    //   data:{
    //     "pickupTime": formData.pickupTime,  
    //     "dropOffTime": formData.dropOffTime,
    //     "bookingType": formData.bookingType, 
    //     "userPhone": formData.userPhone,
    //     "pickupLocation": formData.pickupLocation, 
    //     "pickupLocationId": formData.pickupLocationId,
    //     "dropOffLocation": formData.dropOffLocation,
    //     "dropOffLocationId": formData.dropOffLocationId,
    //     "intermediates": [...formData.intermediates]
    //   },
    //   headers:{
    //     "UserType": "0",
    //     "Content-Type": "application/json",
    //   }
    // })
    // .then((response)=>{
      
    // stopLoading();
       
    //   if(response.data.status === "200"){
        
    //      navigate('/booking',{state:{data:{...formData}, result: [...response.data.data]}})
    //   }
    //   else{
    //     NotificationManager.error('Error while fetching cabs')
    //   }
    //   console.log(response)
    // })
    // .catch((err)=>{
    //   stopLoading()
    //   NotificationManager.error('Error while fetching cabs')
    // })
  }
  return(
    <section className="pt-0 pt-lg-0 position-relative">
      <div className="swiper mySwiper">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <img src={sliderImg} alt="slider-1" />
          </div>
          <div className="swiper-slide">
            <img src={sliderImg} alt="slider-1" />
          </div>
        </div>
        <div className="swiper-pagination"></div>
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
      </div>
      <div className="slide-form">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <ul className="nav nav-pills mb-0 mob-res" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link rounded-0 active"
                    id="outstation-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#outstation-1"
                    type="button"
                    role="tab"
                    aria-controls="outstation-1"
                    aria-selected="true"
                  >
                    Out Station
                  </button>
                </li>
                <li className="nav-item d-none" role="presentation">
                  <button
                    className="nav-link rounded-0 "
                    id="local-airport-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#local-airport-2"
                    type="button"
                    role="tab"
                    aria-controls="local-airport-2"
                    aria-selected="false"
                  >
                    Local / Airport
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                {/* Out Station Tab */}
                <div className="tab-pane fade show active" id="outstation-1" role="tabpanel" aria-labelledby="outstation-tab">
                  <div className="card rounded-top-0 shadow pb-0 mt-n7 mt-sm-n8 mt-lg-0">
                    <div className="card-header border-bottom-0 px-3 py-2">
                      <h6 className="card-title mb-0">Book Your Online Cab</h6>
                    </div>

                    <div className="card-body form-control-border px-3 py-2">
                      <div className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                        <div
                          className="form-check form-check-inline active"
                          id="cab2-one-way-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#cab2-one-way"
                          role="tab"
                          aria-controls="cab2-one-way"
                          aria-selected={formData.bookingType === process.env.REACT_APP_BOOKING_TYPE_ONE_WAY}
                          value={process.env.REACT_APP_BOOKING_TYPE_ONE_WAY}
                          onClick={handleOptionChange}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="cabRadio1"
                            value={process.env.REACT_APP_BOOKING_TYPE_ONE_WAY}
                            checked={formData.bookingType === process.env.REACT_APP_BOOKING_TYPE_ONE_WAY}
                            onChange={handleOptionChange}
                          />
                          <label className="form-check-label" htmlFor="cabRadio1">
                            One Way
                          </label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          id="cab2-round-way-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#cab2-round-way"
                          role="tab"
                          aria-controls="cab2-round-way"
                          aria-selected={formData.bookingType === process.env.REACT_APP_BOOKING_TYPE_ROUND_WAY}
                          value={process.env.REACT_APP_BOOKING_TYPE_ROUND_WAY}
                          onClick={handleOptionChange}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="cabRadio2"
                            value={process.env.REACT_APP_BOOKING_TYPE_ROUND_WAY}
                            checked={formData.bookingType === process.env.REACT_APP_BOOKING_TYPE_ROUND_WAY}
                            onChange={handleOptionChange}
                          />
                          <label className="form-check-label" htmlFor="cabRadio2">
                            Round Trip
                          </label>
                        </div>
                      </div>

                      <div className="tab-content my-2" id="pills-tabContent">
                        {/* One way */}
                        <div className="tab-pane fade show active" id="cab2-one-way" role="tabpanel" aria-labelledby="cab2-one-way-tab">
                          <div className="row ">
                            <div className="col-md-12 ">
                              <DropDownInputBox placeholder={'Select Pickup Location'} label={'Pickup'} handleOnSelectLocation={handleOnSelectPickup} name={'pickupLocation'} keyValue={0} isReset={isFormDataReset}></DropDownInputBox>
                            </div>

                            <div className="col-md-12">
                              <div className="form-fs-lg form-control-transparent">
                                 <DropDownInputBox placeholder={'Select Drop Location'} label={'Drop'} handleOnSelectLocation={handleOnSelectDrop} name={'dropLocation'} keyValue={0} isReset={isFormDataReset}></DropDownInputBox>
                                
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-fs-lg form-control-transparent">
                                <label className="form-label small">Phone No.</label>
                                 <PhoneInputCom
                                   // value={phoneNumber}
                                    onChangePhone={onChangePhoneInput}
                                    isReset={isFormDataReset}
                                  ></PhoneInputCom>
                                
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Round trip */}
                        <div className="tab-pane fade" id="cab2-round-way" role="tabpanel" aria-labelledby="cab2-round-way-tab">
                          <div className="row">
                            <div className="col-md-12 ">
                              <div className="form-fs-lg form-control-transparent">
                                   <DropDownInputBox placeholder={'Select Pickup Location'} label={'Pickup'} handleOnSelectLocation={handleOnSelectPickup} name={'pickupLocation'} keyValue={0} isReset={isFormDataReset}></DropDownInputBox>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-fs-lg form-control-transparent">
                              {
                                [...Array(totalDropLocation)].map((e, i) => (
                                   <DropDownInputBox key={i} placeholder={'Select Drop Location'} label={'Drop'} handleOnSelectLocation={handleOnSelectDrop} name={'dropLocation'} keyValue={i}isReset={isFormDataReset} ></DropDownInputBox>
                                ))
                              }
                             
                              </div>
                            </div>

                            {/* <div className="col-card-body form-control-border px-3 py-2 mt-0">
                              <div>
                                <ul id="myList" className="m-0 p-0 list-unstyled d-flex flex-wrap gap-3"></ul>
                              </div>
                            </div> */}

                            <div className="col-md-12">
                              <button
                            
                                id="dsble"
                                className="rounded p-2 btn btn-warning w-100 mt-2 border-0"
                                onClick={handleMoreDestinationClick}
                              >
                                Add More Destination <span className="fa fa-add"></span>
                              </button>
                            </div>

                            <div className="col-md-12">
                              <div className="form-fs-lg form-control-transparent">
                                  <PhoneInputCom
                                  //  value={phoneNumber}
                                    onChangePhone={onChangePhoneInput}
                                    isReset={isFormDataReset}
                                  ></PhoneInputCom>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-grid mt-3">
                        <button className="btn btn-dark mb-0" onClick={handleSearch}>Search Cabs</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Local/Airport Tab */}
                {/* <div className="tab-pane fade" id="local-airport-2" role="tabpanel" aria-labelledby="local-airport-tab">
                  <div className="card rounded-top-0 shadow pb-0 mt-n7 mt-sm-n8 mt-lg-0">
                    <div className="card-header border-bottom px-3 py-2">
                      <h6 className="card-title mb-0">Book Your Online Cab</h6>
                    </div>

                    <form className="card-body form-control-border px-3 py-3">
                      <div className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                        <div
                          className="form-check form-check-inline active"
                          id="cab2-one-way-tab-2"
                          data-bs-toggle="pill"
                          data-bs-target="#cab2-one-way-2"
                          role="tab"
                          aria-controls="cab2-one-way"
                          aria-selected="true"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="cabRadio3"
                            value="option1"
                            defaultChecked
                          />
                          <label className="form-check-label" htmlFor="cabRadio3">
                            Airport Transfer
                          </label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          id="cab2-round-way-tab-3"
                          data-bs-toggle="pill"
                          data-bs-target="#cab2-round-way-3"
                          role="tab"
                          aria-controls="cab2-round-way"
                          aria-selected="false"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="cabRadio4"
                            value="option2"
                          />
                          <label className="form-check-label" htmlFor="cabRadio4">
                            Local Rental
                          </label>
                        </div>
                      </div>

                      <div className="tab-content my-2" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="cab2-one-way-2" role="tabpanel" aria-labelledby="cab2-one-way-tab">
                          <div className="row g-2 g-md-4">
                            <div className="col-md-12 position-relative">
                              <div className="form-fs-lg form-control-transparent">
                                <DropDownInputBox placeholder={'Select From Airport'} label={'From Airport'} handleOnSelectLocation={handleOnSelectPickup} name={'pickupLocation'}></DropDownInputBox>

                              
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-fs-lg form-control-transparent">
                                   <DropDownInputBox placeholder={'Select To Airport'} label={'To Airport'} handleOnSelectLocation={handleOnSelectDrop} name={'dropLocation'}></DropDownInputBox>
                               
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="form-fs-lg form-control-transparent">
                                <label className="form-label small">Phone Number</label>
                                 <PhoneInputCom
                                    value={phoneNumber}
                                    setValue={onChangePhoneInput}
                                  ></PhoneInputCom>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="cab2-round-way-3" role="tabpanel" aria-labelledby="cab2-round-way-tab">
                          <div className="row g-2 g-md-4">
                            <div className="col-md-12">
                              <div className="form-fs-lg form-control-transparent">
                                 <DropDownInputBox placeholder={'Select City'} label={'Pickup'} handleOnSelectLocation={handleOnSelectPickup} name={'pickupLocation'}></DropDownInputBox>

                            
                              </div>
                            </div>

                            <div className="col-sm-12">
                                <DropDownInputBox placeholder={'Select Package'} label={'Drop'} handleOnSelectLocation={handleOnSelectDrop} name={'dropLocation'}></DropDownInputBox>
                             
                              <div className="form-fs-lg form-control-transparent">
                               
                              </div>
                            </div>

                            <div className="col-sm-12 mt-2">
                              <div className="form-fs-lg form-control-transparent">
                                <label className="form-label small">Enter Phone Number</label>
                                 <PhoneInputCom
                                    value={phoneNumber}
                                    setValue={onChangePhoneInput}
                                  ></PhoneInputCom>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-grid">
                        <button className="btn btn-dark mb-0" onClick={handleSearch}>Search Cabs</button>
                      </div>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DatePickerModal isVisible={isDatePickerModalOpen} closeDatePickerModal={closeDatePickerModal} pickUpDateHandler={handlePickUpDateChange} dropDateHandler={handleDropDateChange} getPriceBtnHandler={handleGetPRiceBtnClick} data={formData}></DatePickerModal>
      <Loader></Loader>
       {/* <LoginModal
        show={showLoginModal}
        handleClose={closeSignUpModal}
      ></LoginModal> */}
      
    </section>
  )
}

export default MainBanner