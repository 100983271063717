import { createContext, useContext, useState } from "react"

const LoginStepContext = createContext();
export const LoginStepProvider = ({ children })=>{
  const [step, setStep] = useState(1);
  
 
  return(
    <LoginStepContext.Provider value={{step, setStep}}>
      {children}
    </LoginStepContext.Provider>
  )
}

export const useLoginStep = () =>{
  return useContext(LoginStepContext)
}
