import React, { useEffect } from "react";
import { Button, Offcanvas, Card } from "react-bootstrap";
import {
  BsPencilSquare,
  BsPerson,
  BsTicketPerforated,
  BsTrash,
  BsSignOut,
  BsVr,
  BsPerson as BsPersonIcon,
  BsWallet2,
  BsCurrencyRupee,
  BsCalendar,
  BsPeople,
} from "react-icons/bs";
import { NotificationManager } from "react-notifications";
import { useBookingManageData } from "../hooks/useManageBookingData";
import { useProfileSection } from "../hooks/useProfileSection";
import { callApi, convertDatetoReadFormat } from "../common/GeneralMethod";
import { ApiHeaders } from "../common/ConstStates";

const ManageBooking = () => {
  const { eachbookingData, setEachBookingData } = useBookingManageData();

  const { activeTab, setActiveTab } = useProfileSection();

  useEffect(() => {
    // console.log(eachbookingData);
  }, [eachbookingData]);

  const handleCancelBooking = async () => {
    const response = await callApi(
      "get",
      "Booking/CancelBooking?bookingId=" + eachbookingData.booking_id,
      {},
      { ...ApiHeaders }
    );

    if (response !== null || response !== undefined) {
      if (response.data.status === "success") {
        NotificationManager.success("Booking Cancelled");
        setActiveTab(2);
      } else {
        NotificationManager.error("Error while processing request");
      }
    } else {
      NotificationManager.error("Error while processing request");
    }
  };

  return (
    <div className="col-lg-8 col-xl-9 ps-xl-5">
      <style>
        {`
                                    .booking_for ul.btype {
                                        list-style: none;
                                        padding-left: 0;
                                        display: flex;
                                        gap: 1.5rem;
                                        align-items: center;
                                    }
                                    .booking_for ul.btype li {
                                        position: relative;
                                    }
                                    .booking_for ul.btype li:after {
                                        content: "\\f135";
                                        font-family: bootstrap-icons !important;
                                        font-size: 1.4rem;
                                        position: absolute;
                                        top: -3px;
                                        font-weight: 500;
                                    }
                                    .booking_for ul.btype li:last-child::after {
                                        display: none;
                                    }
                                    .booking-location {
                                        list-style: none;
                                        padding-left: 0;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    }
                                `}
      </style>
      {
        JSON.stringify(eachbookingData)!=="{}"?(
        <Card border="light" className="bg-transparent">
        <Card.Header className="bg-transparent border-bottom">
          <h5 className="card-header-title">Manage Booking</h5>
        </Card.Header>
        <Card.Body className="p-0 booking_info">
          <div className="booking_for p-3 position-relative">
            <ul className="btype mb-0">
              <li>
                <a
                 
                  className="badge text-bg-warning fw-bold"
                >
                  Out Station
                </a>
              </li>
              <li>
                <a
                  
                  className="badge text-bg-warning fw-bold"
                >
                  {eachbookingData.bookingType === process.env.REACT_APP_BOOKING_TYPE_ONE_WAY ? "One Way" : "Round Trip"}
                </a>
              </li>
            </ul>
            <div className="row justify-content-between text-start mb-2 mt-3">
              <div className="col-md-12">
                <ul className="booking-location">
                  <li>
                    <div>
                      <span className="badge text-bg-success">Pickup</span>
                      <h6>{eachbookingData.pickupLocation}</h6>
                    </div>
                  </li>
                  <li>
                    <span className="badge text-bg-danger">Drop</span>
                    <h6>{eachbookingData.dropOffLocation}</h6>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5">
                <ul className="list-group list-group-borderless">
                  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                    <span className="mb-0">
                      <BsVr className="fa-fw me-2" />
                      Booking ID:
                    </span>
                    <span className="h6 fw-normal mb-0">{eachbookingData.other2}</span>
                  </li>
                  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                    <span className="mb-0">
                      <BsPersonIcon className="fa-fw me-2" />
                      Booked by:
                    </span>
                    <span className="h6 fw-normal mb-0">{eachbookingData.userName}</span>
                  </li>
                  {/* <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                    <span className="mb-0">
                      <BsWallet2 className="fa-fw me-2" />
                      Payment Method:
                    </span>
                    <span className="h6 fw-normal mb-0">Credit card</span>
                  </li> */}
                  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                    <span className="mb-0">
                      <BsCurrencyRupee className="fa-fw me-2" />
                      Total Price:
                    </span>
                    <span className="h6 fw-normal mb-0">
                      <BsCurrencyRupee />
                      {eachbookingData.totalFare}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5">
                <ul className="list-group list-group-borderless">
                  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                    <span className="mb-0">
                      <BsCalendar className="fa-fw me-2" />
                      Booking Date:
                    </span>
                    <span className="h6 fw-normal mb-0">{convertDatetoReadFormat(eachbookingData.createdDate)}</span>
                  </li>
                  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                    <span className="mb-0">
                      <BsCalendar className="fa-fw me-2" />
                      Tour Date:
                    </span>
                    <span className="h6 fw-normal mb-0">{convertDatetoReadFormat(eachbookingData.pickupTime)}</span>
                  </li>
                  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                    <span className="mb-0">
                       <BsCurrencyRupee className="fa-fw me-2" />
                      Advance Payment:
                    </span>
                    <span className="h6 fw-normal mb-0">
                      <BsCurrencyRupee className="fa-fw me-2" />
                      {eachbookingData.paymentAmountAdvance}</span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-12 text-end">
                <Button className="btn btn-danger mb-0" onClick={handleCancelBooking}>Cancel Booking</Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
        )
        :null
      }
      
    </div>
  );
};

export default ManageBooking;
