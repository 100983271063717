import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

export default function OtpInputBox({setValue, isReset}) {
  const [otp, setOtp] = useState('');

  const onChangeInput = (otp) =>{
    setOtp(otp)
    setValue(otp)
  }

  useEffect(()=>{
    if(isReset===true){
      setOtp('')
    }
  },[isReset])

  return (
    <OtpInput id="otp-container"
      value={otp}
      onChange={(otp)=>onChangeInput(otp)}
      numInputs={4}
      renderSeparator={<span>-</span>}
     skipDefaultStyles={true}
     inputStyle={"sminput form-control"}
     inputType="number"
      renderInput={(props) => <input  {...props}  />}
    />
  );
}