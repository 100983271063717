import React, { useEffect, useState } from 'react';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import popDestination from '../images/sliderImg.webp'
import { useLoading } from "../hooks/useLoading";
import { callApi } from "../common/GeneralMethod";
import { NotificationManager } from "react-notifications";
const PopularDestinations = ({sliderItems}) => {
  // const {startLoading, stopLoading} = useLoading();
  // const [sliderItems, setSliderItems] = useState([])
  const settings = {
    container: '.tiny-slider-inner',
    items: 4,
    slideBy: 'page',
    autoplay: true,
    controls: true,
    nav: false,
    gutter: 30,
    autoplayButton: false,          // Ensure autoplay buttons are hidden
    autoplayButtonOutput: false, 
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      768: {
        items: 1
      },
      992: {
        items: 2
      },
      1200: {
        items: 4
      }
    },
    controlsText: [
      '<i class="bi bi-arrow-left"></i>',
      '<i class="bi bi-arrow-right"></i>'
    ]
  };
 
  // const sliderItems = [
  //   {
  //     imgSrc: popDestination,
  //     title: 'Daily 50 Lucky Winners get a Free Stay',
  //     description: 'Valid till: 15 Nov',
  //     link: 'offer-detail.html'
  //   },
  //   {
  //     imgSrc: popDestination,
  //     title: 'Up to 60% OFF',
  //     description: 'On Hotel Bookings Online',
  //     link: 'offer-detail.html'
  //   },
  //   {
  //     imgSrc: popDestination,
  //     title: 'Book & Enjoy',
  //     description: '20% Off on the best available room rate',
  //     link: 'offer-detail.html'
  //   },
  //   {
  //     imgSrc: popDestination,
  //     title: 'Hot Summer Nights',
  //     description: 'Up to 3 nights free!',
  //     link: 'offer-detail.html'
  //   },
  //   {
  //     imgSrc: popDestination,
  //     title: 'Hot Summer Nights',
  //     description: 'Up to 3 nights free!',
  //     link: 'offer-detail.html'
  //   }
  // ];


//   useEffect(()=>{
//     fetchPopularDestinations()
//   },[])

//   const fetchPopularDestinations = async() =>{
//     startLoading();
//     const response = await callApi("get", 'TermsAndPolicies/GetPopularDestinations?Popular=offers', {},{})
//     if(response!==null || response!==undefined){
//       if(response.data.status==="200"){
//       const arr = [];
//       response.data.data.map((data,index)=>{
//         arr.push({...data, imgSrc:popDestination})
//       })
//       setSliderItems([...arr])
//       stopLoading()
//     }
//     else{
//       setSliderItems([])
//       startLoading()
//     }
//     }
//     else{
//       NotificationManager.error("Error while fetching data")
//       stopLoading()
//     }
// //  await axios.get(process.env.REACT_APP_API_URL + 'TermsAndPolicies/GetPopularDestinations?Popular=offers')
// //   .then(function (response) {
// //     // handle success
// //     if(response.data.status==="200"){
// //       const arr = [];
// //       response.data.data.map((data,index)=>{
// //         arr.push({...data, imgSrc:popDestination})
// //       })
// //       setSliderItems([...arr])
// //     }
// //     else{
// //       setSliderItems([])
// //     }
   
// //   })
// //   .catch(function (error) {
// //     // handle error
// //     console.log(error);
// //   });
//   }
  

  return (
    <div className="container">
      <div className="row my-4">
        <div className="col-12 text-center">
          <h3 className="mb-0">Popular Destinations</h3>
        </div>
      </div>
      <TinySlider settings={settings}>
        {sliderItems.map((item, index) => (
          <div key={index} className="">
            <div className="card border rounded-3 overflow-hidden">
            <div className="row g-0 align-items-center">
              <div className="col-sm-12">
                <img src={item.imgSrc} className="card-img rounded-0" alt="" />
              </div>
              <div className="col-sm-12">
                <div className="card-body px-3">
                  <h6 className="card-title">
                    <a  className="stretched-link">{item.packageName}</a>
                  </h6>
                  <p className="mb-0">{item.description}</p>
                </div>
              </div>
            </div>
            </div>
          </div>
        ))}
      </TinySlider>
    </div>
  );
};

export default PopularDestinations;
