import React from "react";
import whyUsImg from '../images/why_us.webp'
import { FaBoltLightning } from "react-icons/fa6";
import { BsLifePreserver } from "react-icons/bs";
import { FaCar } from "react-icons/fa";
import { FaWheelchair } from "react-icons/fa6";

const WhyChooseUs = () =>{
  return (
     <section className="pt-0 pt-md-5">
      <div className="container">
        {/* Title */}
        <div className="row g-4 justify-content-between align-items-center">
          <div className="col-lg-5">
            <div className="card shadow text-center align-items-center position-relative">
              {/* Image */}
              <figure className="position-absolute bottom-0 end-0 d-none d-md-block mb-n5 me-n4">
                {/* Image figure content can be added here if needed */}
                <svg height="400" className="fill-primary opacity-2" viewBox="0 0 340 340">
                  {[...Array(400)].map((_, i) => {
                    const cx = (i % 17) * 24.2 + 2.2;
                    const cy = Math.floor(i / 17) * 24.2 + 2.2;
                    return <circle key={i} cx={cx} cy={cy} r="2.2" />;
                  })}
                </svg>
              </figure>
              <img 
                src={whyUsImg}
                className="fluid-img rounded-3 position-relative shadow" 
                alt="" 
                style={{ height: '500px', objectFit: 'cover' }} 
              />
            </div>
          </div>
          <div className="col-lg-7 ps-lg-5">
            <h3 className="my-3">Why Choose Us</h3>
            <p>Experience the best in cab booking services with our exceptional features.</p>
            <div className="row g-4">
              {/* Item */}
              <div className="col-sm-6">
                <div className="card card-body shadow p-3 h-100">
                  {/* Icon */}
                  <div className="icon-lg bg-primary bg-opacity-10 text-primary rounded-circle mb-3">
                  <FaBoltLightning />
                  </div>
                  <h5>Advance Booking</h5>
                  <p className="mb-0">Book your rides in advance and enjoy a hassle-free experience. Our system ensures you have a cab waiting for you exactly when you need it.</p>
                </div>
              </div>
              {/* Item */}
              <div className="col-sm-6">
                <div className="card card-body shadow p-3 h-100">
                  {/* Icon */}
                  <div className="icon-lg bg-warning bg-opacity-15 text-warning rounded-circle mb-3">
                    <BsLifePreserver />

                  </div>
                  <h5>Secure and Safer</h5>
                  <p className="mb-0">Your safety is our top priority. All our drivers are thoroughly vetted, and our cabs are equipped with the latest safety features to ensure a secure journey.</p>
                </div>
              </div>
              {/* Item */}
              <div className="col-sm-6">
                <div className="card card-body shadow p-3 h-100">
                  {/* Icon */}
                  <div className="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-3">
                  <FaCar />
                  </div>
                  <h5>Vehicle Options</h5>
                  <p className="mb-0">Choose from a wide range of vehicles to suit your needs. Whether you're traveling alone or with a group, we have the perfect cab for you.</p>
                </div>
              </div>
              {/* Item */}
              <div className="col-sm-6">
                <div className="card card-body shadow p-3 h-100">
                  {/* Icon */}
                  <div className="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-3">
                  <FaWheelchair />
                  </div>
                  <h5>Polite Driver</h5>
                  <p className="mb-0">Our drivers are trained to provide the best customer service. Enjoy a pleasant ride with our courteous and professional drivers.</p>
                </div>
              </div>		
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default WhyChooseUs;