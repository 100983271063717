import { createContext, useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";

const PageContext = createContext();
export const PageProvider = ({ children })=>{
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setCurentPage] = useState("Home");
  
  const setPage = (value) =>{
    
      // Get the current pathname
  const currentRoute = location.pathname;

  if(currentRoute==="/booking"){
    
    navigate("/")
    setCurentPage(value)
  }
  else{
    setCurentPage(value)
  }
}
  return(
    <PageContext.Provider value={{page, setPage}}>
      {children}
    </PageContext.Provider>
  )
}

export const usePage = () =>{
  return useContext(PageContext)
}
