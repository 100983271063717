import React, { useState } from "react";

import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegUserCircle } from "react-icons/fa";
import { NotificationContainer } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useLoginModal } from "../hooks/useLoginModal";
import { useProfileSection } from "../hooks/useProfileSection";
import {ReactComponent as AppStoreIcon} from "../images/elements/app-store.svg";
import {ReactComponent as GooglePlayIcon} from "../images/elements/google-play.svg";
import LoginModal from "./LoginModal";
import LogoContent from "./LogoContent";
import MenuContent from "./MenuContent";
import TopAlert from "./TopAlert";
import { usePage } from "../hooks/usePage";
const Header = () => {
  const {page, setPage} = usePage()
 const { user } = useAuth();
 const { setActiveTab} = useProfileSection()
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for off-canvas menu
  
  const {showLoginModal, setShowLoginModal} = useLoginModal();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openSignUpModal = () => {
    setShowLoginModal(true);
  };

  const closeSignUpModal = () => {
    setShowLoginModal(false);
  };

  const handleProfileIconClick = ()=>{
    setActiveTab(1)
    setPage("Profile")
    // navigate('/profile')
  }

  

  return (
    <>
    <NotificationContainer/>
      <TopAlert></TopAlert>
      <header className="navbar-light header-sticky">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <LogoContent></LogoContent>

            {/*  */}
            <button
              className="navbar-toggler ms-auto mx-3 me-xl-0 p-0 p-sm-1"
              type="button"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-animation">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

            <div className={`navbar-collapse collapse `}>
              <MenuContent setPage={setPage}></MenuContent>
            </div>

            <ul className="nav flex-row align-items-center list-unstyled ms-xl-auto">
              <li className="nav-item ms-2 d-none d-sm-block">
                {
                  window.localStorage.getItem("user") !==null && user!==null ? 
                  <FaRegUserCircle className="fs-3 p-1" style={{cursor:"pointer"}} onClick={handleProfileIconClick}/>
                    
                    :(<button
                  className="btn btn-sm btn-primary-soft mb-0"
                  onClick={openSignUpModal}
                >
                  <FontAwesomeIcon
                    icon={faRightToBracket}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Log In
                </button> )
                }
              </li>
            </ul>
          </div>
        </nav>

        {/*  */}
        <div
          className={`offcanvas offcanvas-start ${isMenuOpen ? "show" : ""}`}
          tabIndex="-1"
          id="offcanvasMenu"
          aria-labelledby="offcanvasMenuLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasMenuLabel">
              <LogoContent></LogoContent>
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleMenu}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div id="cloneToOffCanvas" style={{ height: "calc(80vh - 100px)" }}>
              <MenuContent setPage={setPage}></MenuContent>
            </div>

            <div className="downloadApp">
              <h6>Download our app and access exclusive features</h6>
              <div className="d-flex align-items-center gap-1">
                <div className="android">
                  <a href="">
                    <AppStoreIcon className="h-30px" ></AppStoreIcon>
                    {/* <img src={googlePlayIcon} alt="" /> */}
                  </a>
                </div>
                <div className="ios">
                  <a href="">
                    <GooglePlayIcon className="h-30px" ></GooglePlayIcon>
                    {/* <img src={appStoreIcon} className="h-50px" alt="" /> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <LoginModal
        show={showLoginModal}
        handleClose={closeSignUpModal}
        phoneNo=""
      ></LoginModal>

  
    </>
  );
};

export default Header;
