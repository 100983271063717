import React, { useEffect, useState } from 'react';
import { useAuth } from "../hooks/useAuth";
import { checkIsNullOrUndefined } from "../common/GeneralMethod";

const ProfileCompletion = () => {
  const {user} = useAuth()
  const [volume, setVolume] = useState("70")

  
  useEffect(()=>{
    checkLocations(user)
  },[user])
  const checkLocations = (user) => {
  const { homeLocation, workLocation, emergencyContactNo } = user;

  // Count the number of non-empty fields
  let nonEmptyCount = 0;
  if (homeLocation) nonEmptyCount++;
  if (workLocation) nonEmptyCount++;
  if (emergencyContactNo) nonEmptyCount++;

  // Return value based on the count of non-empty fields
  if (nonEmptyCount === 3) {
    setVolume("100")
  } else if (nonEmptyCount === 2) {
    setVolume("90")
  } else if (nonEmptyCount === 1) {
    setVolume("80")
  } 
};
  return (
    <div className="bg-light rounded p-3">
      <h6>Complete Your Profile</h6>
      <div className="progress progress-sm bg-success bg-opacity-10">
        <div className="progress-bar bg-success aos" role="progressbar" style={{ width: '85%' }} aria-valuenow={volume} aria-valuemin="0" aria-valuemax="100">
          <span className="progress-percent-simple h6 fw-light ms-auto">{volume+"%"}</span>
        </div>
      </div>
      <p className="mb-0">Get the best out of booking by adding the remaining details!</p>
      <div className="bg-body rounded p-3 mt-3">
        <ul className="list-inline hstack flex-wrap gap-2 justify-content-between mb-0">
          <li className="list-inline-item h6 fw-normal mb-0">
            <a><i className="bi bi-check-circle-fill text-success me-2"></i>Verified Email</a>
          </li>
          <li className="list-inline-item h6 fw-normal mb-0">
            <a><i className="bi bi-check-circle-fill text-success me-2"></i>Verified Mobile Number</a>
          </li>
          <li className="list-inline-item h6 fw-normal mb-0">
            <a className="text-primary"><i className="bi bi-plus-circle-fill me-2"></i>Complete Basic Info</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileCompletion;
