import { createContext, useState,useContext } from "react";

const LoginModalContext = createContext();

export const LoginModalProvider = ({children}) =>{
  const [showLoginModal, setShowLoginModal] = useState(false)

  return <LoginModalContext.Provider value={{showLoginModal, setShowLoginModal}}>{children}</LoginModalContext.Provider>
}

export const useLoginModal = () => {
  return useContext(LoginModalContext);
};