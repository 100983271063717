import "./App.css";
import React from "react";
import { Routes } from "react-router-dom";
import Aboutus from "./aboutus/Aboutus";
import { Route } from "react-router-dom";
import Home from "./Home/Home";
import Contact from "./ContactUs/Contact";

import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Booking from "./BookingPage/Booking";
import { AuthProvider } from "./hooks/useAuth";
import { LoginModalProvider } from "./hooks/useLoginModal";
import 'react-notifications/lib/notifications.css';

import Policy from "./Components/Policy";
import BookingNew from "./BookingPage/BookingNew";
import UserProfile from "./Profile/UserProfile";
import { LoadingProvider } from "./hooks/useLoading";
import { LoginStepProvider } from "./hooks/useLoginStep"
import { BookNowModalProvider } from "./hooks/useBookNowModal";
import { BookingDataProvider } from "./hooks/useBookingData";
import { ProfileActiveProvider } from "./hooks/useProfileSection";
import { PageProvider } from "./hooks/usePage";
import { ManageBookingProvider } from "./hooks/useManageBookingData";
function App() {
  return (
    <>
    <AuthProvider>
      <PageProvider>
      <LoginModalProvider>
      <BookNowModalProvider>
      <LoadingProvider>
      <LoginStepProvider>
      <BookingDataProvider>
        <ManageBookingProvider>
      <ProfileActiveProvider>
      <Routes>
      
        <Route path="/" element={<Home></Home>} />
        {/* <Route path="/about" element={<Aboutus></Aboutus>} /> */}
        {/* <Route path="/contact-us" element={<Contact></Contact>} /> */}
        {/* <Route path="/terms-and-conditions" element={<TermsAndConditions></TermsAndConditions>} />
        <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy></CancellationRefundPolicy>} />
         <Route path="/privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>} /> */}
         <Route path="/forgot-password" element={<ForgotPassword></ForgotPassword>} />
         <Route path="/policy" element={<Policy/>} />

         <Route path="/booking" element={<BookingNew></BookingNew>} />
          {/* <Route path="/profile" element={<UserProfile></UserProfile>} /> */}


      </Routes>
      </ProfileActiveProvider>
      </ManageBookingProvider>
      </BookingDataProvider>
      </LoginStepProvider>
      </LoadingProvider>
      </BookNowModalProvider>
      </LoginModalProvider>
      </PageProvider>
      </AuthProvider>
    </>
  );
}

export default App;
