import { createContext, useContext, useState } from "react"

const ProfileActiveContext = createContext();
export const ProfileActiveProvider = ({ children })=>{
  const [activeTab, setActiveTab] = useState(1);
  
 
  return(
    <ProfileActiveContext.Provider value={{activeTab, setActiveTab}}>
      {children}
    </ProfileActiveContext.Provider>
  )
}

export const useProfileSection = () =>{
  return useContext(ProfileActiveContext)
}
