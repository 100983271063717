import React, { useEffect, useState } from "react";
import { callApi, convertDatetoReadFormat } from "../common/GeneralMethod";
import { useAuth } from "../hooks/useAuth";
import { ApiHeaders } from "../common/ConstStates";
import { NotificationManager } from "react-notifications";
import { useLoading } from "../hooks/useLoading";
import { useProfileSection } from "../hooks/useProfileSection";
import { useBookingManageData } from "../hooks/useManageBookingData";
import profileImg from '../images/slider/imageprofile.webp';
import { usePage } from "../hooks/usePage";
const BookingsCard = () => {
  const { user } = useAuth();
  const {page,setPage} = usePage();
  const { startLoading, stopLoading } = useLoading();
  const { activeTab, setActiveTab } = useProfileSection();
  const [activeTabBooking, setActiveTabBooking] = useState("tab-1");

  const [bookingPending, setBookingPending] = useState([]);
  const [bookingCancel, setBookingCancel] = useState([]);
  const [bookingCompleted, setBookingCompleted] = useState([]);
  const { setEachBookingData } = useBookingManageData();
  const handleTabChange = (tabId) => {
    setActiveTabBooking(tabId);
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    startLoading();
    const response = await callApi(
      "get",
      "Booking/GetBookingHistory?PhoneNo=" + user.phoneNo,
      {},
      { ...ApiHeaders }
    );
    // console.log(response);
    if (response !== null) {
      if (response.data.data.length > 0) {
        const tempP = [];
        const tempC = [];
        const tempCp = [];
        response.data.data.map((data, index) => {
          if (data.bookingStatus === "pending") {
            tempP.push(data);
          } else if (data.bookingStatus === "complete") {
            tempCp.push(data);
          } else if (data.bookingStatus === "Cancel") {
            tempC.push(data);
          }
        });
        setBookingPending([...tempP]);
        setBookingCancel([...tempC]);
        setBookingCompleted([...tempCp]);
        stopLoading();
      } else {
        stopLoading();
      }
    } else {
      stopLoading();
      NotificationManager.error("Error while proccessing request");
    }
  };

  const handleManageBooking = (data) => {
    setEachBookingData(data);
    setActiveTab(4);
  };

  return (
    <div className="col-lg-8 col-xl-9 mb-3">
      <div className="card border bg-transparent">
        {/* Card header */}
        <div className="card-header bg-transparent border-bottom">
          <h4 className="card-header-title">My Bookings</h4>
        </div>

        {/* Card body START */}
        <div className="card-body p-0">
          {/* Tabs */}
          <ul className="nav nav-tabs nav-bottom-line nav-responsive nav-justified">
            <li className="nav-item">
              <a
                className={`nav-link mb-0 ${
                  activeTabBooking === "tab-1" ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleTabChange("tab-1")}
              >
                <i className="bi bi-briefcase-fill fa-fw me-1"></i>Upcoming
                Booking
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link mb-0 ${
                  activeTabBooking === "tab-2" ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleTabChange("tab-2")}
              >
                <i className="bi bi-x-octagon fa-fw me-1"></i>Cancelled Booking
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link mb-0 ${
                  activeTabBooking === "tab-3" ? "active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => handleTabChange("tab-3")}
              >
                <i className="bi bi-patch-check fa-fw me-1"></i>Completed
                Booking
              </a>
            </li>
          </ul>

          {/* Upcoming booking content START */}
          <div className="tab-content p-2 p-sm-4" id="nav-tabContent">
            {/* Tab content item START */}
            <div
              className={`tab-pane fade ${
                activeTabBooking === "tab-1" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <h6>{"Upcoming booking (" + bookingPending.length + ")"}</h6>
              {/* Card item START */}
              {bookingPending.map((data, index) => {
                return (
                  <div className="card border mb-4">
                    {/* Card header */}
                    <div className="card-header border-bottom d-md-flex justify-content-md-between align-items-center">
                      {/* Icon and Title */}
                      <div className="d-flex align-items-center">
                       
                          <img src={user.userImage === null ? profileImg : user.userImage} className="icon-lg bg-light rounded-circle flex-shrink-0"></img>
                          {/* <i className="fa-solid fa-car"></i> */}
                        
                        <div className="ms-2">
                          <h6 className="card-title mb-0">
                            {data.pickupLocation +
                              " to " +
                              data.dropOffLocation}
                          </h6>
                          <ul className="nav nav-divider small">
                            <li className="nav-item">
                              {"Booking ID: " + data.other2}
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                      <div className="mt-2 mt-md-0">
                        <a
                          className="btn btn-primary-soft mb-0"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleManageBooking(data)}
                        >
                          Manage Booking
                        </a>
                      </div>
                    </div>
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6 col-md-4">
                          <span>Pickup Time</span>
                          <h6 className="mb-0">{convertDatetoReadFormat(data.pickupTime)}</h6>
                        </div>
                        
                           <div className="col-sm-6 col-md-4">
                          <span>Drop Time</span>
                          <h6 className="mb-0">{data.bookingType === process.env.REACT_APP_BOOKING_TYPE_ONE_WAY ?"Not Applicable" : convertDatetoReadFormat(data.dropOffTime)}</h6>
                        </div> 
                       
                        <div className="col-md-4">
                          <span>Booked by</span>
                          <h6 className="mb-0">{data.userName}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* Card item END */}
            </div>
            {/* Tab content item END */}

            {/* Cancel Booking item START */}
            <div
              className={`tab-pane fade ${
                activeTabBooking === "tab-2" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <h6>{"Cancelled booking (" + bookingCancel.length + ")"}</h6>
              {/* Card item START */}
              {bookingCancel.map((data, index) => {
                return (
                  <div className="card border">
                    {/* Card header */}
                    <div className="card-header border-bottom d-md-flex justify-content-md-between align-items-center">
                      {/* Icon and Title */}
                      <div className="d-flex align-items-center">
                     
                         <img src={user.userImage === null ? profileImg : user.userImage} className="icon-lg bg-light rounded-circle flex-shrink-0"></img>
                          {/* <i className="fa-solid fa-car"></i> */}
                       
                        <div className="ms-2">
                          <h6 className="card-title mb-0">
                            {data.pickupLocation +
                              " to " +
                              data.dropOffLocation}
                          </h6>
                          <ul className="nav nav-divider small">
                            <li className="nav-item">
                              {"Booking ID: " + data.other2}
                            </li>
                            {/* <li className="nav-item">AC</li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="mt-2 mt-md-0">
                        {/* <a href="#" className="btn btn-primary-soft mb-0">
                          Manage Booking
                        </a> */}
                        <p className="text-danger text-md-end mb-0">
                          Booking cancelled
                        </p>
                      </div>
                    </div>
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6 col-md-4">
                          <span>Cancel Time</span>
                          <h6 className="mb-0">{data.modifyDate}</h6>
                        </div>
                        {/* <div className="col-sm-6 col-md-4">
                          <span>Check out time</span>
                          <h6 className="mb-0">Tue 12 Aug 4:00 PM</h6>
                        </div> */}
                        <div className="col-md-4">
                          <span>Cancel by</span>
                          <h6 className="mb-0">{data.userName}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* Card item END */}
            </div>
            {/* Tab content item END */}

            {/* Completed Booking item START */}
            <div
              className={`tab-pane fade ${
                activeTabBooking === "tab-3" ? "show active" : ""
              }`}
              id="tab-3"
            >

              {
                bookingCompleted.length === 0 ? (
          <div className="bg-mode shadow p-4 rounded overflow-hidden">
                <div className="row g-4 align-items-center">
                  {/* Content */}
                  <div className="col-md-9">
                    <h6>Looks like you have never booked with BOOKING</h6>
                    <h4 className="mb-2">
                      When you book your trip will be shown here.
                    </h4>
                    <a
                      
                      className="btn btn-primary-soft mb-0"
                      onClick={()=>setPage("Home")}
                    >
                      Start booking now
                    </a>
                  </div>
                  {/* Image */}
                  <div className="col-md-3 text-end">
                    <img
                      src="assets/images/element/17.svg"
                      className="mb-n5"
                      alt=""
                    />
                  </div>
                </div>
              </div>
                )
                :
                (
                  bookingCompleted.map((data, index) => {
                return (
                  <>
                   <h6>{"Completed booking (" + bookingCompleted.length + ")"}</h6>
                  <div className="card border mb-4">
                    {/* Card header */}
                    <div className="card-header border-bottom d-md-flex justify-content-md-between align-items-center">
                      {/* Icon and Title */}
                      <div className="d-flex align-items-center">
                        
                          <img src={user.userImage === null ? profileImg : user.userImage} className="icon-lg bg-light rounded-circle flex-shrink-0"></img>
                          {/* <i className="fa-solid fa-car"></i> */}
                          
                        
                        <div className="ms-2">
                          <h6 className="card-title mb-0">
                            {data.pickupLocation +
                              " to " +
                              data.dropOffLocation}
                          </h6>
                          <ul className="nav nav-divider small">
                            <li className="nav-item">
                              {"Booking ID: " + data.other2}
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                      <div className="mt-2 mt-md-0">
                        {/* <a
                          className="btn btn-primary-soft mb-0"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleManageBooking(data)}
                        >
                          Manage Booking
                        </a> */}
                      </div>
                    </div>
                    {/* Card body */}
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6 col-md-4">
                          <span>Pickup address</span>
                          <h6 className="mb-0">{data.pickupLocation}</h6>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <span>Drop address</span>
                          <h6 className="mb-0">{data.dropOffLocation}</h6>
                        </div>
                        <div className="col-md-4">
                          <span>Booked by</span>
                          <h6 className="mb-0">{data.userName}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                );
              }) 
                   
                )
              }
              
            </div>
            {/* Tab content item END */}
          </div>
        </div>
        {/* Card body END */}
      </div>
    </div>
  );
};

export default BookingsCard;
