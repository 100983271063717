import axios from "axios";
import React, { useEffect, useState } from 'react';
import { NotificationManager } from "react-notifications";
import { useAuth } from "../hooks/useAuth";
import { useLoading } from "../hooks/useLoading";
import profileImg from '../images/slider/imageprofile.webp';
import { callApi, checkIsNullOrUndefined } from "../common/GeneralMethod";
import { ApiHeaders } from "../common/ConstStates";
const PersonalInfoForm = () => {
  const {user, login} = useAuth()
  const {startLoading, stopLoading} = useLoading();
  const [formData, setFormData] = useState({...user})


const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Handle invalid date input (e.g., when dateString is "0001-01-01T00:00:00")
  if (date.getFullYear() === 1) {
    return 'Invalid Date';
  }

  // Format the date to YYYY-MM-DD
  const formattedDate = date.toISOString().split('T')[0];
  return formattedDate;
};

const formatToISOString = (dateString) => {
  const date = new Date(dateString);
  
  // If date is invalid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Convert to ISO string (YYYY-MM-DDTHH:mm:ss)
  return date.toISOString().split('.')[0]; // Removes the milliseconds part
};


  const handleFileChange = async(event) => {
    //Call API for Image Change
    if(event.target.files.length > 0){
       const file = event.target.files[0]
    // console.log(file)
    startLoading();
    const fileFormData = new FormData()
    fileFormData.append('file', file)
    fileFormData.append('FileName', "UserImage")
    fileFormData.append('PhoneNo', user.phoneNo)
    fileFormData.append('UserId', user.userId)
    // console.log(fileFormData)
    try{
      const response = await axios.post(process.env.REACT_APP_API_URL +'Drivers/UploadFile', fileFormData, {
        headers: {
           "UserType": process.env.REACT_APP_USER_TYPE,
          'Content-Type': 'multipart/form-data',
        },
      });
   
    if(response!==null ){
      if(response.data.status === "success"){
        NotificationManager.success("File Uploaded Successfully")
        setFormData({...formData, userImage: "https://cabkro.com/" + response.data.fileUrl })
        // console.log(process.env.REACT_APP_API_IMAGE_URL + response.data.fileUrl)
        login({...user, userImage: "https://cabkro.com/" + response.data.fileUrl})
      }
      else{
        NotificationManager.error("Error while uploading Image")
      }
      stopLoading()
    }
    else{
      NotificationManager.error("Error while uploading Image")
      stopLoading()
    }
    }
    catch(err){
      NotificationManager.error("Error while uploading Image")
      stopLoading()
    }
     
    }
   
   // convertToBase64(event.target.files[0])
    
    
  };

  const handleInputChange = (e)=>{
    if(e.target.type==="date"){
      setFormData({...formData, [e.target.name]: formatToISOString(e.target.value)})
    }
    else if (e.target.type === "radio"){
      setFormData({...formData, [e.target.name]: parseInt(e.target.id)})
    }
    else if(e.target.type === "number"){
      if(e.target.value.length <= 10){
setFormData({...formData, [e.target.name]: e.target.value})
      }
    }
    else{
setFormData({...formData, [e.target.name]: e.target.value})
    }
    
  }

  const handleSaveClick = async(e)=>{
    e.preventDefault()
    startLoading()
    const response = await callApi("post", "Auth/UpdateUser", {...formData},{...ApiHeaders});

    if(response!==null ){
      // console.log('userupdate',response)
      if(response.data.status === "success"){
        setFormData({...response.data.data})
        login({...response.data.data, userImage: process.env.REACT_APP_API_IMAGE_URL+ response.data.data.userImage})
        NotificationManager.success("User Detals Update Successfully")
      }
      else{
        NotificationManager.error("Error while processing data")
      }
      stopLoading()
    }
    else{
      NotificationManager.error("Error while processing data")
      stopLoading()
    }
  }
  // useEffect(()=>{
  //   // console.log(formData)
  // },[formData])
  return (
    <div className="card border">
      <div className="card-header border-bottom">
        <h4 className="card-header-title">Personal Information</h4>
      </div>
      <div className="card-body">
        <form className="row g-3">
          <div className="col-12">
            <label className="form-label">Upload your profile photo<span className="text-danger">*</span></label>
            <div className="d-flex align-items-center">
              <label className="position-relative me-4" htmlFor="uploadfile-1" title="Replace this pic">
                <span className="avatar avatar-xl">
                  <img id="uploadfile-1-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src={formData.userImage === null ? profileImg : formData.userImage} alt="Profile" />
                </span>
              </label>
              <label className="btn btn-sm btn-primary-soft mb-0" htmlFor="uploadfile-1">Change</label>
              <input id="uploadfile-1" className="form-control d-none" type="file" onChange={handleFileChange}     accept=".jpg, .jpeg, .png, .pdf" />
            </div>
          </div>
          <div className="col-md-6">
            <label className="form-label">First Name<span className="text-danger">*</span></label>
            <input type="text" className="form-control" value={checkIsNullOrUndefined(formData.userFirstName)} placeholder="Enter your first name" onChange={handleInputChange} name="userFirstName"/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Last Name<span className="text-danger">*</span></label>
            <input type="text" className="form-control" value={checkIsNullOrUndefined(formData.userLastName)} placeholder="Enter your last name" onChange={handleInputChange} name="userLastName"/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Mobile number<span className="text-danger">*</span></label>
            <input type="number" className="form-control" value={checkIsNullOrUndefined(formData.phoneNo)} placeholder="Enter your mobile number" name="phoneNo" onChange={handleInputChange} maxLength={10}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Email address<span className="text-danger">*</span></label>
            <input type="email" className="form-control" value={checkIsNullOrUndefined(formData.userEmail)} placeholder="Enter your email id" name="userEmail" onChange={handleInputChange}/>
          </div>
          
          {/* <div className="col-md-6">
            <label className="form-label">Nationality<span className="text-danger">*</span></label>
            <select className="form-select">
              <option value="">Select your country</option>
              <option>USA</option>
              <option selected>Paris</option>
              <option>India</option>
              <option>UK</option>
            </select>
          </div> */}
          <div className="col-md-6">
            <label className="form-label">Date of Birth<span className="text-danger">*</span></label>
            <input type="date" className="form-control"  placeholder="Enter date of birth" name={"dob"} onChange={handleInputChange} value={formatDate(checkIsNullOrUndefined(formData.dob))}  max={new Date().toISOString().split('T')[0]}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Select Gender<span className="text-danger">*</span></label>
            <div className="d-flex gap-4">
              <div className="form-check radio-bg-light">
                <input className="form-check-input" type="radio" name="gender" id={1} checked={formData.gender===1 ? true:false} onChange={handleInputChange} />
                <label className="form-check-label" htmlFor="male">Male</label>
              </div>
              <div className="form-check radio-bg-light">
                <input className="form-check-input" type="radio" name="gender" id={2} checked={formData.gender===2 ? true:false} onChange={handleInputChange}/>
                <label className="form-check-label" htmlFor="female">Female</label>
              </div>
              <div className="form-check radio-bg-light">
                <input className="form-check-input" type="radio" name="gender" id={0} checked={formData.gender===0 ? true:false} onChange={handleInputChange}/>
                <label className="form-check-label" htmlFor="other">Others</label>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Emergency Mobile number<span className="text-danger"></span></label>
            <input type="number" className="form-control" value={checkIsNullOrUndefined(formData.emergencyContactNo)} placeholder="Enter your mobile number" name="emergencyContactNo" onChange={handleInputChange} maxLength={10}/>
          </div>
          <div className="col-md-6">
            <label className="form-label">Work Location<span className="text-danger"></span></label>
            <input type="text" className="form-control" value={checkIsNullOrUndefined(formData.workLocation)} placeholder="Enter your last name" onChange={handleInputChange} name="workLocation"/>
          </div>
          <div className="col-12">
            <label className="form-label">Address</label>
            <textarea className="form-control" rows="3" value={checkIsNullOrUndefined(formData.homeLocation)} id="homeLocation" onChange={handleInputChange}></textarea>
          </div>
          <div className="col-12 text-end">
            <button className="btn btn-dark mb-0" onClick={handleSaveClick}>Save Changes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfoForm;
