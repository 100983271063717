import React, { useState } from 'react';

import Header from "../common/Header";
import Footer from "../common/Footer";
import BackToTop from "../common/BackToTop";
const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    const [passwordType, setPasswordType] = useState('password');

    const togglePassword = () => {
        setPasswordType(passwordType === 'password' ? 'text' : 'password');
    };

    return (
      <>
       <Header></Header>
        <div className="main-content my-5 py-3">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-lg-5 shadow">
                        {step === 1 && (
                            <div className="enter-ph-email p-4 card card-body">
                                <p className="text-center">Get OTP in your Phone Number and Gmail id!</p>
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label">Email address</label>
                                        <input type="email" className="form-control" id="email" placeholder="" />
                                    </div>
                                    <div className="divider text-center"><span className="bg-white">Or</span></div>
                                    <div className="mb-3">
                                        <label className="form-label">Phone Number</label>
                                        <input type="tel" className="form-control" id="phone" placeholder="" />
                                    </div>
                                    <div className="actions">
                                        <button type="button" className="btn btn-primary w-100" onClick={() => setStep(2)}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {step === 2 && (
                            <div className="otp text-center p-4 card card-body">
                                <div className="circle-check d-flex justify-content-center align-items-center text-white fs-3">
                                    <i className="fa-regular fa-circle-check"></i>
                                </div>
                                <h6 className="mb-2 text-uppercase text-secondary fw-normal">Enter OTP Code</h6>
                                <form>
                                    <input type="text" className="sminput form-control" maxLength="4" />
                                    <input type="text" className="sminput form-control" maxLength="4" />
                                    <input type="text" className="sminput form-control" maxLength="4" />
                                    <input type="text" className="sminput form-control" maxLength="4" />
                                    <div className="actions mt-3">
                                        <button type="button" className="btn btn-primary w-100" onClick={() => setStep(3)}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {step === 3 && (
                            <div className="newpassword p-4 rounded card card-body">
                                <h6 className="mb-2 text-uppercase text-secondary fw-normal text-center">Set New Password</h6>
                                <p className="text-center">Please Enter New Password You Want To Create and Repeat It!</p>
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label">New Password</label>
                                        <div className="input-group">
                                            <input type={passwordType} className="form-control" id="pass" placeholder="" />
                                            <button type="button" className="input-group-text toggleye" onClick={togglePassword}>
                                                <i className={`fa-regular ${passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Confirm Password</label>
                                        <div className="input-group">
                                            <input type={passwordType} className="form-control" id="confirm" placeholder="" />
                                            <button type="button" className="input-group-text toggleye" onClick={togglePassword}>
                                                <i className={`fa-regular ${passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <button type="button" className="btn btn-primary w-100">Proceed</button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
           <Footer></Footer>
      <BackToTop></BackToTop>
      </>
    );
};

export default ForgotPassword;
