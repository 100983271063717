import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { usePage } from "../hooks/usePage";
import { ReactComponent as ExpressCardSvg } from '../images/elements/expresscard.svg';
import { ReactComponent as MasterCardSvg } from '../images/elements/mastercard.svg';
import { ReactComponent as PaypalSvg } from '../images/elements/paypal.svg';
import { ReactComponent as VisaSvg } from '../images/elements/visa.svg';
import logo from '../images/logo.webp';
const Footer = ({popularCities,popularCitiesNames}) => {
  const {page, setPage} = usePage()
// const {startLoading, stopLoading} = useLoading();
  // const [popularCities, setPopularCities] = useState([])
  // const [popularCitiesNames, setPopularCitiesNames] = useState([])


  //  useEffect(()=>{
  //   fetchPopularDestinations()
  // },[])

//   useEffect(()=>{
// console.log(popularCitiesNames, popularCities)
//   },[popularCitiesNames, popularCities])
//   const fetchPopularDestinations = async() =>{
//     startLoading()
//     const response = await callApi("get", 'TermsAndPolicies/GetPopularDestinations?Popular=cities',{},{})
//     if(response!==null || response!==undefined){
//       if(response.data.status==="200"){
//       console.log(response)
//       const result = response.data.data
//       const uniqueNames = [...new Set(result.map(item => item.pickupCity))];
//       setPopularCities([...result])
//       setPopularCitiesNames([...uniqueNames])
//       stopLoading()
//     }
//     else{
//       setPopularCities([])
//       setPopularCitiesNames([])
//       stopLoading()
//     }
//     }
//     else{
//       NotificationManager.error("Error while fetching data")
//     }
// //  await axios.get(process.env.REACT_APP_API_URL + 'TermsAndPolicies/GetPopularDestinations?Popular=cities')
// //   .then(function (response) {
// //     // handle success
// //     if(response.data.status==="200"){
// //       console.log(response)
// //       const result = response.data.data
// //       const uniqueNames = [...new Set(result.map(item => item.pickupCity))];
// //       setPopularCities([...result])
// //       setPopularCitiesNames([...uniqueNames])
// //     }
// //     else{
// //       setPopularCities([])
// //       setPopularCitiesNames([])
// //     }
   
// //   })
// //   .catch(function (error) {
// //     // handle error
// //     console.log(error);
// //   });
//   }


  return (
    <footer className="bg-dark pt-5">
      <div className="container">
        {/* Row START */}
        <div className="row g-4">
          {/* Widget 1 START */}
          <div className="col-lg-3">
            {/* logo */}
            <a href="index.html">
              <img className="ftLog" src= {logo} alt="logo" />
            </a>
            <p className="my-3 text-body-secondary">
              Departure defective arranging rapturous did believe him all had supported.
            </p>
            <p className="mb-2">
              <a  className="text-body-secondary text-primary-hover">
                <i className="bi bi-telephone me-2"></i>+1234 568 963
              </a>
            </p>
            <p className="mb-0">
              <a  className="text-body-secondary text-primary-hover">
                <i className="bi bi-envelope me-2"></i>example@gmail.com
              </a>
            </p>
          </div>
          {/* Widget 1 END */}

          {/* Widget 2 START */}
          <div className="col-lg-9 ms-auto">
            <div className="row g-4">
              {
                popularCitiesNames.map((data,index)=>{
                  return (<div key={index}  className="col-6 col-md-4">
                    <h6 className="text-white mb-2 mb-md-3 text-uppercase">Cab From {data}</h6>
                    <ul className="nav flex-column text-primary-hover">
                      {
                        popularCities.map((data1,index1)=>(
                          data1.pickupCity === data ?  <li key={index1} className="nav-item" ><a className="nav-link text-body-secondary" onClick={()=>setPage("travelinfo")}>{data1.description}</a></li>: null
                        ))
                      }
                    </ul>
                  </div>)
                })
              }
              

              {/* Link block */}
              {/* <div className="col-6 col-md-4">
                <h6 className="text-white mb-2 mb-md-3 text-uppercase">Cab From Delhi</h6>
                <ul className="nav flex-column text-primary-hover">
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Delhi to Agra</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Delhi to Jaipur</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Delhi to Chandigarh</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Delhi to Amritsar</a></li>
                </ul>
              </div> */}

              {/* Link block */}
              {/* <div className="col-6 col-md-4">
                <h6 className="text-white mb-2 mb-md-3 text-uppercase">Cab From Bangalore</h6>
                <ul className="nav flex-column text-primary-hover">
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Bangalore to Mysore</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Bangalore to Coorg</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Bangalore to Ooty</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Bangalore to Pondicherry</a></li>
                </ul>
              </div> */}

              {/* Link block */}
              {/* <div className="col-6 col-md-4">
                <h6 className="text-white mb-2 mb-md-3 text-uppercase">Cab From Mumbai</h6>
                <ul className="nav flex-column text-primary-hover">
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Mumbai to Shirdi</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Mumbai to Shani Shingnapur</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Mumbai to Mahabaleshwar</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Mumbai to Nashik</a></li>
                  <li className="nav-item"><a className="nav-link text-body-secondary" href="#">Taxi from Mumbai to Pune</a></li>
                </ul>
              </div> */}
            </div>
          </div>
          {/* Widget 2 END */}
        </div>
        {/* Row END */}

        {/* Tops Links */}
        {/* <div className="row mt-5">
          <h6 className="mb-2 text-white text-uppercase">POPULAR CITIES</h6>
          <div className="tabNav">
            
            <ul id="list-container" className="list-inline text-primary-hover lh-lg">
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Agra</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Ahmdabad</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Delhi</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Almora</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Alwar</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Simla</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Nanital</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Mussoorie</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Dhonalti</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Rishikesh</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Haridwar</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Jaipur</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Srinagar</a></li>
              <li className="list-inline-item"><a href="#" className="text-body-secondary">Ajmer</a></li>
             
            </ul>
            <a href="" id="show-all-button" className="text-theme">Show More..</a>
          </div>
        </div> */}

        {/* Payment and card */}
        <div className="row g-4 justify-content-between mt-0 mt-md-2">
          {/* Payment card */}
          <div className="col-sm-7 col-md-6 col-lg-4">
            <h6 className="text-white mb-2 text-uppercase">Payment & Security</h6>
            <ul className="list-inline mb-0 mt-3">
              <li className="list-inline-item">
                <a><PaypalSvg className="h-50px w-40px"></PaypalSvg></a>
             
              </li>
              <li className="list-inline-item">
                <a><VisaSvg className="h-40px w-40px"></VisaSvg></a>
               
              </li>
              <li className="list-inline-item">
                <a><MasterCardSvg className="h-40px w-40px"></MasterCardSvg></a>
               
              </li>
              <li className="list-inline-item">
                <a><ExpressCardSvg className="h-40px w-40px"></ExpressCardSvg></a>
        
              </li>
            </ul>
          </div>

          {/* Social media icon */}
          <div className="col-sm-5 col-md-6 col-lg-3 text-sm-end">
            <h6 className="text-white mb-2">Follow us on</h6>
            <ul className="list-inline mb-0 mt-3">
              <li className="list-inline-item">
                <a className="btn btn-sm px-2 bg-facebook mb-0" ><FaFacebookF></FaFacebookF></a>
              </li>
              <li className="list-inline-item">
                <a className="btn btn-sm shadow px-2 bg-instagram mb-0" ><FaInstagram></FaInstagram></a>
              </li>
              <li className="list-inline-item">
                <a className="btn btn-sm shadow px-2 bg-twitter mb-0" ><FaTwitter></FaTwitter></a>
              </li>
              <li className="list-inline-item">
                <a className="btn btn-sm shadow px-2 bg-linkedin mb-0" ><FaLinkedinIn></FaLinkedinIn></a>
              </li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <hr className="mt-4 mb-0" />

        {/* Bottom footer */}
        <div className="row">
          <div className="container">
            <div className="d-lg-flex justify-content-between align-items-center py-3 text-center text-lg-start">
              <div className="nav mt-2 mt-lg-0">
                <ul className="list-inline text-primary-hover mx-auto mb-0">
                  <li className="list-inline-item me-0">
                    <a className="nav-link text-body-secondary py-1" style={{cursor:"pointer"}} onClick={()=>setPage("PrivacyPolicy")} state={{heading:"Privacy Policy", apiName: "TermsAndPolicies/PrivacyPolicy"}}>Privacy policy</a>
                  </li>
                  <li className="list-inline-item me-0">
                <a className="nav-link text-body-secondary py-1" style={{cursor:"pointer"}} onClick={()=>setPage("TermsAndPolicies")}>Terms and conditions</a>
                  </li>
                  <li className="list-inline-item me-0">
                    <a className="nav-link text-body-secondary py-1 pe-0" style={{cursor:"pointer"}}  onClick={()=>setPage("CancellationPolicy")}>Refund policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
