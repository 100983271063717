import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";



 export const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
};

export const callApi = async(methodType, url, apiData, apiHeader)=>{
    try {
    const response = await axios({
      method: methodType,
      url: process.env.REACT_APP_API_URL + url,
      data: { ...apiData },
      headers: { ...apiHeader }
    });
    return response; // return the response if successful
  } catch (err) {
    // console.log("isnide err",err)
   
    return err.response
    //throw err; // re-throw the error if you want to handle it later
  }
}

export const GetHomePageData = async() =>{
  try{
const responsePopularDestinations = await callApi("get", 'TermsAndPolicies/GetPopularDestinations?Popular=offers', {},{})

  const responseFQA = await callApi("get", 'TermsAndPolicies/GetFAQs',{},{})

  const responsePopularCities = await callApi("get", 'TermsAndPolicies/GetPopularDestinations?Popular=cities',{},{})

  const obj = {
    responsePopularDestinations: responsePopularDestinations,
    responseFQA : responseFQA,
    responsePopularCities : responsePopularCities
  }

  return obj;
  }
  catch(err){
    return null
  }
  

}


export const checkIsNullOrUndefined = (value)=>{
  return (value===null || value === undefined ? "" : value)
}

export const getTomorrowAtSixAM = () => {
   const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Add 1 day to the current date
    tomorrow.setHours(6, 0, 0, 0); // Set time to 6 AM

    // Manually format the date as 'YYYY-MM-DDTHH:MM'
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(tomorrow.getDate()).padStart(2, "0");
    const hours = String(tomorrow.getHours()).padStart(2, "0");
    const minutes = String(tomorrow.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };


  export const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Add one day to today's date
    return tomorrow.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  };

  export const convertDatetoReadFormat = (dateString) => {
 const date = new Date(dateString);

  // Format the date and time
  const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' };
  const optionsTime = { hour: 'numeric', minute: '2-digit', hour12: true };

  const formattedDate = date.toLocaleDateString('en-GB', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-GB', optionsTime).replace(/(\d+)(:\d+)?\s?(AM|PM)/, '$1 $3');

  return `${formattedDate}, ${formattedTime}`;
};

export const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  }

  ;