import React, { useEffect, useState } from 'react';
import { useLoading } from "../hooks/useLoading";
import { callApi } from "../common/GeneralMethod";
import { NotificationManager } from "react-notifications";

const FAQAccordion = ({faqs}) => {
  // const {startLoading, stopLoading} = useLoading();
  const [activeIndex, setActiveIndex] = useState(null);
  // const [faqs, setFaqs] = useState([])

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // useEffect(() => {
  //  fetchFAQ()
  
  // },[])

  // const fetchFAQ = async()=>{
  //   startLoading()
  //   console.log("inside here FAQ")
  //   const response = await callApi("get", 'TermsAndPolicies/GetFAQs',{},{})
  //   if(response!==null || response!==undefined){
  //     setFaqs([...response.data.data])
  //     stopLoading();
  //   }
  //   else{
  //     NotificationManager.error("Error while fetching data")
  //     stopLoading()
  //   }
  // }

  return (
    <section className="pt-0 pt-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-xl-8 mx-auto">
            <h2 className="mb-4 text-center">Frequently Asked Questions</h2>
            <div className="accordion accordion-icon accordion-bg-light" id="accordionFaq">
              {faqs.map((faq, index) => (
                <div className="accordion-item mb-3" key={index}>
                  <h6 className="accordion-header font-base" id={`heading-${index}`}>
                    <button
                      className={`accordion-button fw-bold rounded pe-5 ${activeIndex === index ? '' : 'collapsed'}`}
                      type="button"
                      onClick={() => toggleAccordion(index)}
                      aria-expanded={activeIndex === index}
                      aria-controls={`collapse-${index}`}
                    >
                      {faq.question}
                    </button>
                  </h6>
                  <div
                    id={`collapse-${index}`}
                    className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                    aria-labelledby={`heading-${index}`}
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body mt-3 pb-0">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQAccordion;
