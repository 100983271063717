import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Content from "../common/Content";
import { scrollToTop } from "../common/GeneralMethod";
import { useLoading } from "../hooks/useLoading";

const Policy = ({apiName,heading}) => {
  const location = useLocation();
  const { startLoading, stopLoading} = useLoading()
  const [policyData, setPolicyData] = useState([]);

  useEffect(() => {
      scrollToTop();

    const fetchData = async () => {
      startLoading()
      await axios({
        method: "get",
        url: process.env.REACT_APP_API_URL + apiName,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response)
          setPolicyData([...response.data.data]);
          stopLoading()

          //console.log(response.data.data);
        })
        .catch(function (err) {
          // console.log(err);
          stopLoading()
        });
    };
    fetchData();
  }, [apiName]);

  return (
    <div>
      {/* <Header></Header> */}

      <main>
        <div className="main-content my-5 py-3">
          <div className="privacyPolicy">
            <div className="container">
              <h3 className="mb-3">{heading}</h3>
              <ol>
                {policyData.map((policy, index) => {
                  // console.log(policy.privacyPolicy);
                  // console.log(policy.termUse);
                  if (
                    apiName === "TermsAndPolicies/PrivacyPolicy"
                  ) {
                    return (
                      <li key={policy.id}>
                        <Content data={policy.privacyPolicy}></Content>
                      </li>
                    );
                  } else if (
                    apiName ===
                    "TermsAndPolicies/GetTermsAndUses"
                  ) {
                    return (
                      <li key={policy.id}>
                        <Content data={policy.termUse}></Content>
                      </li>
                    );
                  } else if (
                    apiName ===
                    "TermsAndPolicies/CancellationPolicy"
                  ) {
                    return (
                      <li key={policy.id}>
                        <Content data={policy.policy}></Content>
                      </li>
                    );
                  }
                })}
              </ol>
            </div>
          </div>
        </div>
      </main>

      {/* <Footer></Footer> */}
      {/* <BackToTop></BackToTop> */}
    </div>
  );
};

export default Policy;
