import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useLoginStep } from "../hooks/useLoginStep";
import { callApi } from "../common/GeneralMethod";
import { useAuth } from "../hooks/useAuth";
import { NotificationManager } from "react-notifications";
import loginImg from "../images/car-pop.png";
import PhoneInputCom from "../Components/PhoneInput";
import OtpInputBox from "../Components/OtpInputBox";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import BookNow from "../BookingPage/BookNow";
import { useBookNowModal } from "../hooks/useBookNowModal";
import { useBookingData } from "../hooks/useBookingData";
import { useLoading } from "../hooks/useLoading";
import { ApiHeader, ApiHeaders } from "./ConstStates";

const LoginModal = ({ show, handleClose, phoneNo }) => {
  const { step, setStep } = useLoginStep();
  const { login } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const { showBookNowModal, setBookNowModalState } = useBookNowModal();
  const { eachBookingData, setEachBookingData } = useBookingData();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isFormDataReset, setIsFormDataReset] = useState(false);
  const [response, setResponse] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    //Reset form data
    setIsFormDataReset(true);
    //Set phone no field to the prop phone no
    setPhoneNumber(phoneNo);
    setOtp("");
  }, []);

  useEffect(() => {
    if (step === 2 && phoneNo.length === 10) {
      sendOtp();
    }
  }, [step, phoneNo]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseModal = () => {
    setStep(1);
    setPhoneNumber("");
    setOtp("");
    setResponse({});
    handleClose();
  };

  const onChangePhoneInput = (value) => {
    setPhoneNumber(value);
  };

  const handleLoginInClick = () => {
    if (phoneNumber === "+91") {
      NotificationManager.error("Please Enter Phone no");
      return;
    }
    if (phoneNumber.length < 13) {
      NotificationManager.error("Please Correct Phone no");
      return;
    }

    // Send otp on provided number
    sendOtp();
  };

  const sendOtp = async () => {
    startLoading();
    const sendOtpResponse = await callApi(
      "get",
      "Auth/SendOtp?phone=" + phoneNumber.replace("+91", ""),
      {},
      { ...ApiHeader }
    );
    //Check otp response is not null
    if (sendOtpResponse !== null) {
      //check response is success
      if (sendOtpResponse.data.status === "success") {
        setResponse({ ...sendOtpResponse.data });
        NotificationManager.success("OTP Sent Successfully");
        //show second step
        setStep(2);
        stopLoading();
      } else {
        NotificationManager.error("Error while sending OTP");
        stopLoading();
      }
    } else {
      NotificationManager.error("Error while sending OTP");
      stopLoading();
    }
  };

  const onChangeOtpInput = (value) => {
    setOtp(value);
  };

  const handleVerifyOtpClick = () => {
    if (otp.length < 4) {
      NotificationManager.error("Please Enter OTP");
      return;
    }

    //Verify entered otp
    verifyOtp();
  };

  const verifyOtp = async () => {
    startLoading();
    const verifyOtpResponse = await callApi(
      "post",
      "Auth/VerifyOTP",
      {
        userType: response.userType,
        otp: parseInt(otp),
        phoneNo: phoneNumber.replace("+91", ""),
        password: "",
      },
      { ...ApiHeaders }
    );

    if (verifyOtpResponse !== null) {
      const result = verifyOtpResponse.data;
      if (result.status === "success") {
        if (result.isRegister === false) {
          setStep(3);
          setResponse({ ...result.data });
        } else {
          handleCloseModal();
          login({
            ...result.data,
            userImage: "https://cabkro.com/" + result.data.userImage,
          });
          // Getting modal from booking page
          if (phoneNo !== "") {
            //Open final booking page
            setBookNowModalState(true);
          }
        }
        stopLoading();
        NotificationManager.success("OTP Verified Successfully");
      } else {
        stopLoading();
        NotificationManager.error("Incorrect OTP");
      }
    } else {
      stopLoading();
      NotificationManager.error("Error while verifying OTP");
    }
  };

  const handleFirstNameChange = (e) => {
    setResponse({ ...response, userFirstName: e.target.value });
  };

  const handleLastNameChange = (e) => {
    setResponse({ ...response, userLastName: e.target.value });
  };

  const handleEmailChange = (e) => {
    setResponse({ ...response, userEmail: e.target.value });
  };

  const handleDobChange = (e) => {
    setResponse({ ...response, dob: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setResponse({ ...response, password: e.target.value });
  };

  const handleMaleCheckboxChange = (e) => {
    setResponse({ ...response, gender: e.target.value });
  };

  const handleFemaleCheckboxChange = (e) => {
    setResponse({ ...response, gender: e.target.value });
  };

  const registerUser = async (e) => {
    e.preventDefault();
    startLoading();
    const registerResponse = await callApi(
      "post",
      "Auth/RegisterUser",
      {
        ...response,
        phoneNo: phoneNumber.replace("+91", ""),
        createdDate: new Date().toISOString(),
      },
      { ...ApiHeader }
    );
    if (registerResponse !== null) {
      const result = registerResponse.data;
      if (result.isRegister === true) {
        login({
          ...result.data,
          userImage: "https://cabkro.com/" + result.data.userImage,
        });
        setBookNowModalState(true);
        NotificationManager.success("User Register Successfully");
        handleCloseModal();
        stopLoading();
      } else {
        NotificationManager.error(result.message);
        stopLoading();
      }
    } else {
      NotificationManager.error("Error while User Registration");
      stopLoading();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
        className="rounded-modal"
      >
        <Modal.Body className="p-0 overflow-hidden">
          <Row>
            {/* Left side image */}
            <Col md={6}>
              <div className="loginImg h-100 p-3">
                <img
                  src={loginImg}
                  alt="login"
                  className="img-fluid avatar-img"
                />
              </div>
            </Col>
            {/* Right side UI */}
            <Col md={5}>
              {/* Show phone no input screen */}
              {step === 1 && (
                <div className="loginContainer px-1 py-5">
                  <h5 className="mb-2 text-uppercase text-secondary fw-normal">
                    Log In
                  </h5>
                  <p>Enter your credential to access your account</p>
                  <Form>
                    <Form.Group className="mb-4">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInputCom
                        onChangePhone={onChangePhoneInput}
                        isReset={isFormDataReset}
                      ></PhoneInputCom>
                    </Form.Group>
                    <Button
                      variant="primary"
                      className="w-100 mb-2"
                      onClick={handleLoginInClick}
                    >
                      Continue
                    </Button>
                  </Form>
                </div>
              )}
              {step === 2 && (
                <div className="loginContainer px-1 py-5">
                  <h5 className="mb-2 text-uppercase text-secondary fw-normal">
                    Log In
                  </h5>
                  <p>{"Enter OTP send to " + phoneNumber}</p>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Enter OTP</Form.Label>
                      <OtpInputBox
                        setValue={onChangeOtpInput}
                        isReset={isFormDataReset}
                      ></OtpInputBox>
                    </Form.Group>

                    <Button
                      variant="primary"
                      className="w-100 mb-2"
                      onClick={handleVerifyOtpClick}
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              )}
              {/* Register screen */}
              {step === 3 && (
                <div className="loginContainer px-1 py-5">
                  <h5 className="mb-2 text-uppercase text-secondary fw-normal">
                    Register
                  </h5>
                  <Form onSubmit={registerUser}>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>
                          First Name<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          onChange={handleFirstNameChange}
                          required
                        />
                      </Form.Group>{" "}
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          onChange={handleLastNameChange}
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        Email address<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder=""
                        onChange={handleEmailChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        DOB<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder=""
                        onChange={handleDobChange}
                        max={new Date().toISOString().split("T")[0]}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Password<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder=""
                          onChange={handlePasswordChange}
                          required
                        />
                        <InputGroup.Text
                          variant="outline-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        Gender<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                          inline
                          label="Male"
                          name="group1"
                          type={"radio"}
                          id={`inline-radio-1`}
                          value={1}
                          onChange={handleMaleCheckboxChange}
                          required
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="group1"
                          type={"radio"}
                          id={`inline-radio-2`}
                          value={2}
                          onChange={handleFemaleCheckboxChange}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Button
                      type="sumbit"
                      variant="primary"
                      className="w-100 mb-2"
                    >
                      Register
                    </Button>
                  </Form>
                </div>
              )}
            </Col>
          </Row>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            className="btn-close position-absolute closeModal"
            style={{ top: "10px", right: "10px" }}
          ></Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
