import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShareAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faTwitterSquare, faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import imageSrc from '../images/delhi.jpg'

const TravelInfo = () => {
  return (
    <section className="py-0">
      <div className="container position-relative">
        {/* Title and button START */}
        <div className="row mb-3">
          <div className="col-12 mb-3">
            {/* Meta */}
            <div className="d-lg-flex justify-content-lg-between mb-1">
              {/* Title */}
              <div className="mb-2 mb-lg-0">
                <h1 className="fs-3">Delhi To Agra Cab</h1>
              </div>
              {/* Buttons */}
              <ul className="list-inline text-end">
                {/* Heart icon */}
                <li className="list-inline-item">
                  <a  className="btn btn-sm btn-light px-2">
                    <FontAwesomeIcon icon={faHeart} />
                  </a>
                </li>
                {/* Share icon */}
                <li className="list-inline-item dropdown">
                  {/* Share button */}
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdownShare">
                      <FontAwesomeIcon icon={faShareAlt} />
                    </Dropdown.Toggle>
                    {/* Dropdown menu */}
                    <Dropdown.Menu align="end" className="shadow rounded">
                      <Dropdown.Item ><FontAwesomeIcon icon={faTwitterSquare} className="me-2" />Twitter</Dropdown.Item>
                      <Dropdown.Item ><FontAwesomeIcon icon={faFacebookSquare} className="me-2" />Facebook</Dropdown.Item>
                      <Dropdown.Item ><FontAwesomeIcon icon={faLinkedin} className="me-2" />LinkedIn</Dropdown.Item>
                      <Dropdown.Item ><FontAwesomeIcon icon={faCopy} className="me-2" />Copy link</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <ul className="nav nav-pills nav-pills-dark mb-3" id="tour-pills-tab" role="tablist">
              <li className="nav-item me-2">
                <a className="nav-link" data-bs-toggle="tab" href="#AboutAgra">About Agra</a>
              </li>
              <li className="nav-item me-2">
                <a className="nav-link" data-bs-toggle="tab" href="#TouristAttractions">Popular Attractions</a>
              </li>
              <li className="nav-item me-2">
                <a className="nav-link" data-bs-toggle="tab" href="#RouteInformation">Route Information</a>
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane show active" id="AboutAgra">
                <div className="row">
                  <div className="col-md-7 mb-2">
                    <p>Delhi, the capital of India, is a vibrant city that blends history, culture, and modernity. It's one of the oldest cities in the world, with a history that spans thousands of years, and is often described as the heart of India. Here’s a brief overview:</p>
                    <h2 className="h5">History</h2>
                    <p>Delhi has been the seat of several empires, including the Mauryan, Mughal, and British. The city is home to iconic historical landmarks such as:</p>
                    <ul>
                      <li>Red Fort: A symbol of India's rich Mughal history.</li>
                      <li>Qutub Minar: A towering minaret and a UNESCO World Heritage Site.</li>
                      <li>India Gate: A war memorial built in honor of Indian soldiers who died in World War I.</li>
                    </ul>
                    <h2 className="h5">Attractions</h2>
                    <p>Some of the must-visit spots in Delhi include:</p>
                    <ul>
                      <li>Humayun’s Tomb: Another UNESCO World Heritage site.</li>
                      <li>Lotus Temple: A Bahá'í House of Worship known for its stunning architecture.</li>
                      <li>Akshardham Temple: A modern temple that showcases Indian culture and spirituality.</li>
                      <li>Chandni Chowk: One of the oldest and busiest markets in India.</li>
                    </ul>
                    <h2 className="h5">Transportation</h2>
                    <p>Delhi is well-connected by the Delhi Metro, buses, and taxis, making it easy for both locals and tourists to get around. It also has an international airport, Indira Gandhi International Airport, connecting it to global destinations.</p>
                    <p>Overall, Delhi offers a rich blend of history, culture, and modern urban life, making it a unique destination.</p>
                  </div>
                  <div className="col-md-5 mb-2">
                    <img src={imageSrc} alt="Agra" />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="RouteInformation">
                Route Information
              </div>
              <div className="tab-pane" id="TouristAttractions">
                Tourist Attractions
              </div>
            </div>
          </div>
        </div>
        {/* Title and button END */}
      </div>
    </section>
  );
};

export default TravelInfo;
