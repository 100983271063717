import React, { useEffect, useState } from 'react';
import { scrollToTop } from "./GeneralMethod";
import { FaLongArrowAltUp } from "react-icons/fa";


const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 800) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  
    return (
        <div className={`back-top ${isVisible ? 'back-top-show' : ''}`} onClick={scrollToTop}>
            {/* You can add an icon or text here */}
            <FaLongArrowAltUp />
        </div>
    );
};

export default BackToTop;
