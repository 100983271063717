import { createContext, useState,useContext } from "react";

const BookNowModalContext = createContext();

export const BookNowModalProvider = ({children}) =>{
  const [showBookNowModal, setShowBookNowModal] = useState(false)

  const setBookNowModalState = (value)=>{
    setShowBookNowModal(value)
    if(value === true){
document.body.style.overflowY = 'hidden';
    }
    else{
document.body.style.overflowY = 'visible';
    }
    
  }
  return <BookNowModalContext.Provider value={{showBookNowModal, setBookNowModalState}}>{children}</BookNowModalContext.Provider>
}

export const useBookNowModal = () => {
  return useContext(BookNowModalContext);
};