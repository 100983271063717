import { createContext, useMemo, useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({children}) =>{
  const [user, setUser] = useLocalStorage("user",null)

  const login = async(data) =>{
    setUser(data)
  }

  const logout = () =>{
    setUser(null)
  }

  const value = useMemo(()=>({
      user, login,logout
    }),[user])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext);
};