import { createContext, useState,useContext } from "react";

const BookingDataContext = createContext();

export const BookingDataProvider = ({children}) =>{
  const [bookData, setBookData] = useState({})

  return <BookingDataContext.Provider value={{bookData, setBookData}}>{children}</BookingDataContext.Provider>
}

export const useBookingData = () => {
  return useContext(BookingDataContext);
};