import BackToTop from "../common/BackToTop";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { useProfileSection } from "../hooks/useProfileSection";
import BookingsCard from "./BookingsCard";
import ChangePassword from "./ChangePassword";
import DeleteAccountCard from "./DeleteAccountCard";
import ManageBooking from "./ManageBooking";
import PersonalInfoForm from "./PersonalInfoForm";
import ProfileCompletion from "./ProfileCompletion";
import Sidebar from "./Sidebar";
import UpdateEmail from "./UpdateEmail";

const UserProfile = ()=>{
  const {activeTab, setActiveTab} = useProfileSection();
  return(
    <div>
      {/* <Header></Header> */}
      <div className="pt-3">

     
        <div className="container">
      <div className="row g-4 g-lg-5">
        <Sidebar />
        {activeTab ===1 &&(
           <div className="col-lg-8 col-xl-9">
          <div className="vstack gap-4">
            <ProfileCompletion />
            <PersonalInfoForm />
            {/* <UpdateEmail /> */}
            <ChangePassword />
          </div>
         
        </div>
        )}
        {activeTab===2&&(
          <BookingsCard></BookingsCard>
        )}

        {activeTab===3 &&(
          <DeleteAccountCard></DeleteAccountCard>
        )}

        {activeTab===4 &&(
          <ManageBooking></ManageBooking>
        )}
       
      </div>
    </div>
     </div>
      {/* <Footer></Footer> */}
      {/* <BackToTop></BackToTop> */}
    </div>
  )
}

export default UserProfile;